









































import {
  Component, Prop, VModel, Watch,
} from 'vue-property-decorator';
import { CustomerData } from '@/store/customers/types';
import { OrganizationData } from '@/store/organizations/types';
import AdminPanelMixin from '../AdminPanelMixin.vue';
import CustomerEditDialog from './CustomerEditDialog.vue';

@Component({
  components: {
    CustomerEditDialog,
  },
})
export default class CustomerDialogMultiSelectCustomer extends AdminPanelMixin {
  @VModel() modelValue!: CustomerData[] | undefined;

  @Prop({ default: null }) organizations: OrganizationData[] | undefined;

  @Prop({ default: false }) requireOrganization: boolean | undefined;

  showNewCustomerDialog = false;

  customerValues = [];

  customerNameSearch = null;

  currentEditItem = {};

  valid = false as boolean;

  async mounted(): Promise<void> {
    if (!this.customerDataList) {
      await this.fetchCustomerData();
    }
    if (this.modelValue) {
      this.setLocalValues();
    }
  }

  @Watch('modelValue')
  onModelValueChange(): void {
    this.setLocalValues();
  }

  @Watch('organizations')
  onOrganizationChange(): void {
    if (this.requireOrganization && this.modelValue) {
      // If the required organization is changed
      // and it does not match the organization guid of modelValue
      // then reset to make sure the customer's organization matches the intended organization
      this.modelValue.filter((cust) => this.organizations.some((org) => org.guid === cust.guid));
    }
  }

  /**
   * @description Sets all local values from the modelValue.
   */
  setLocalValues(): void {
    this.customerValues = this.modelValue.map((cust) => cust.name);
    this.customerNameSearch = '';
  }

  @Watch('showNewCustomerDialog')
  onShowNewCustomerDialogChange(): void {
    if (!this.showNewCustomerDialog) {
      // If closing the dialog, then reset the search
      this.setLocalValues();
      this.currentEditItem = {};
    }
  }

  /**
   * @description Checks for a new user in the combobox
   * @remarks If an existing user is selected, then select them. If not, then open up the popup
   */
  checkforNewUserAdd(): void {
    const foundValues = this.customerValues
      .map((customerValue: string) => this.customersList.find((u) => u.name === customerValue));

    const missingIndex = foundValues.findIndex((x) => !x);
    if (missingIndex === -1) {
      this.modelValue = foundValues;
    }
  }

  /**
   * @description Sets modelValue to value of newCustomer and sets local values
   * @param {CustomerData} newCustomer
   */
  updateLocalCustomer(newCustomer: CustomerData): void {
    this.modelValue.push(newCustomer);
    this.setLocalValues();
  }

  /**
   * @description Get a list of all user's full names.
   * @returns {string[]} list of user full names
   */
  get customersList(): CustomerData[] {
    if (!this.customerDataList) {
      return [];
    }
    if (this.requireOrganization) {
      if (!this.organizations) {
        return [];
      }
      return this.customerDataList
        .filter((cust) => this.organizations.some((org) => cust.organizationGuid === org.guid));
    }
    return this.customerDataList;
  }

  get customersStringList(): string[] {
    return this.customersList.map((cust) => cust.name);
  }

  /**
   * @description On pressing enter, open up new org dialog if the search is not a valid item
   */
  createNewOrganization(): void {
    const foundValues = this.customerValues
      .map((customerValue: string) => this.customersList.find((u) => u.name === customerValue));
    const missingIndex = foundValues.findIndex((x) => !x);
    if (missingIndex !== -1) {
      this.currentEditItem = {
        customerName: this.customerValues[missingIndex],
        address: '',
        systemOwner: null,
        email: '',
        phoneNumber: null,
        organization: null,
      };
      this.showNewCustomerDialog = true;
      (this.$refs.customerValuesComboBox as any).isFocused = false;
    }
  }

  /**
   * @description when leaving focus, make sure the search is a valid value
   */
  onBlur(): void {
    this.customerNameSearch = '';
  }
}
