// Store Role tokens here
export const NONE = 'none';
export const CREW_LEAD = '5a05bb80-a54e-11ec-bcd3-efa2f92373e4';
export const CREW_MEMBER_GUID = '5a05bb80-a54e-11ec-bcd3-efa2f92373e5';
export const CUSTOMER_GUID = '45696b00-e107-11ec-a869-2b45f636ca36';
export const CUSTOMER_SUPPORT_GUID = '5a05bb80-a54e-11ec-bcd3-efa2f92373f0';
export const DEMO_USER_READ_ONLY_GUID = '6cfdb680-446f-11ef-9b21-eb227d655b8f';
export const DEMO_USER_WRITE_GUID = '6d00c3c0-446f-11ef-9b21-c38418a9102d';
export const OPERATIONS_MANAGER_GUID = '5a05bb80-a54e-11ec-bcd3-efa2f92373e9';
export const PROJECT_MANAGER_GUID = 'd5e61d00-d33e-11ea-a42f-6f4431b74d9a';
export const REPORTING_MANAGER_GUID = '5a05bb80-a54e-11ec-bcd3-efa2f92373e8';
export const REPORTING_CONTRACTOR_GUID = 'a22a3000-7f5b-11ef-a87d-3f1a900662da';
export const REPORTING_TECHNICIAN_GUID = 'd5e6441a-d33e-11ea-a432-173994d6bee3';
export const SUPER_USER_GUID = 'f3b58a80-321f-11ee-b17b-5f4718720564';
export const SUPERVISOR_GUID = '5a05bb80-a54e-11ec-bcd3-efa2f92373e6';
export const SYSTEM_ADMIN_GUID = '5a05bb80-a54e-11ec-bcd3-efa2f92373e7';
export const SYSTEM_MANAGER_GUID = 'cb46be40-7178-11ef-8cfe-9b570b512ea4';
export const FIELD_CREW = ['311d14dd-0fab-48b2-b50b-766dc019de3c', 'd5e5f5f0-d33e-11ea-a42e-230598c92e28'];

export const AuthUserRoles = [
  {
    id: '',
    name: 'Crew Lead',
    guid: CREW_LEAD,
    manages: [],
  },
  {
    id: '',
    name: 'Crew Member',
    guid: CREW_MEMBER_GUID,
    manages: [],
  },
  {
    id: '',
    name: 'Customer',
    guid: CUSTOMER_GUID,
    manages: [],
  },
  {
    id: '',
    name: 'Customer Support',
    guid: CUSTOMER_SUPPORT_GUID,
    manages: [
      'Crew Member',
      'Crew Lead',
      'Supervisor',
      'Project Manager',
      'Operations Manager',
      'Supervisor',
      'System Admin',
      'Project Manager ',
      'Reporting Contractor',
      'Reporting Tech',
      'Reporting Manager',
      'Operations Manager',
      'Write Access User',
      'Read Access User',
      'System Manager',
    ],
  },
  {
    id: '',
    name: 'Read Access User',
    guid: DEMO_USER_READ_ONLY_GUID,
    manages: [],
  },
  {
    id: '',
    name: 'Write Access User',
    guid: DEMO_USER_WRITE_GUID,
    manages: [],
  },
  {
    id: '',
    name: 'Operations Manager',
    guid: OPERATIONS_MANAGER_GUID,
    manages: [
      'Crew Member',
      'Crew Lead',
      'Supervisor',
      'Project Manager',
      'Write Access User',
      'Read Access User',
    ],
  },
  {
    id: '',
    name: 'Project Manager',
    guid: PROJECT_MANAGER_GUID,
    manages: [
      'Crew Member',
      'Crew Lead',
      'Supervisor',
      'Write Access User',
      'Read Access User',
    ],
  },
  {
    id: '',
    name: 'Reporting Contractor',
    guid: REPORTING_CONTRACTOR_GUID,
    manages: [],
  },
  {
    id: '',
    name: 'Reporting Manager',
    guid: REPORTING_MANAGER_GUID,
    manages: ['Reporting Contractor', 'Reporting Tech'],
  },
  {
    id: '',
    name: 'Reporting Technician',
    guid: REPORTING_TECHNICIAN_GUID,
    manages: [],
  },
  {
    id: '',
    name: 'Super User',
    guid: SUPER_USER_GUID,
    manages: [
      'Crew Member',
      'Crew Lead',
      'Supervisor',
      'System Admin',
      'Project Manager',
      'Reporting Contractor',
      'Reporting Tech',
      'Reporting Manager',
      'Operations Manager',
      'Customer Support',
      'Write Access User',
      'Read Access User',
      'System Manager',
    ],
  },
  {
    id: '',
    name: 'Supervisor',
    guid: SUPERVISOR_GUID,
    manages: ['Crew Member', 'Crew Lead'],
  },
  {
    id: '',
    name: 'System Admin',
    guid: SYSTEM_ADMIN_GUID,
    manages: [
      'Crew Member',
      'Crew Lead',
      'Supervisor',
      'Project Manager',
      'Reporting Contractor',
      'Reporting Tech',
      'Reporting Manager',
      'Operations Manager',
      'Write Access User',
      'Read Access User',
      'System Manager',
    ],
  },
  {
    id: '',
    name: 'System Manager',
    guid: SYSTEM_MANAGER_GUID,
    manages: [
      'Crew Member',
      'Crew Lead',
      'Supervisor',
      'Project Manager ',
      'Reporting Contractor',
      'Reporting Technician',
      'Reporting Manager ',
      'Operations Manager',
      'Read Access User',
      'Write Access User ',
    ],
  },
];

export function getRoleString(role: string): string {
  const roleObj = AuthUserRoles.find((r) => r.id === role);
  return roleObj === undefined ? 'ROLE NOT FOUND' : roleObj.name;
}

export function getRolesArray(roles: string[]): string[] {
  return roles && roles.length > 0
    ? roles.map((role) => getRoleString(role))
    : [];
}

export function getRolesString(roles: string[]): string {
  return getRolesArray(roles).join(', ');
}

export function getRoleId(role: string): string {
  const roleObj = AuthUserRoles.find((r) => r.name === role);
  return roleObj === undefined ? 'ROLE NOT FOUND' : roleObj.id;
}

export function getRoleIdByGuid(roleGuid: string): string {
  const roleObj = AuthUserRoles.find((r) => r.guid === roleGuid);
  return roleObj === undefined ? 'ROLE NOT FOUND' : roleObj.id;
}

export function getRoleGuid(role: string): string {
  const roleObj = AuthUserRoles.find((r) => r.name === role);
  return roleObj === undefined ? 'ROLE NOT FOUND' : roleObj.guid;
}

export function getRoleGuidString(role: string): string {
  const roleObj = AuthUserRoles.find((r) => r.guid === role);
  return roleObj === undefined ? 'ROLE NOT FOUND' : roleObj.name;
}

export function getRoleGuidById(roleId: string): string {
  const roleObj = AuthUserRoles.find((r) => r.id === roleId);
  return roleObj === undefined ? 'ROLE NOT FOUND' : roleObj.guid;
}

/**
 * Checks if a given role can manage another role.
 * @param managingRole The managing role
 * @param managedRole The managed role.
 * @returns true if the user can manage, false otherwise
 */
export function canUserManageRole(managingRole: string, managedRole: string): boolean {
  return AuthUserRoles.find((hier) => hier.name === managingRole).manages.includes(managedRole);
}

export function getRoles(rolesInfo: {name: string, id: string}[]): void {
  AuthUserRoles.forEach((authRole) => {
    const foundRole = rolesInfo.find((role) => role.name === authRole.name);
    if (foundRole) {
    // eslint-disable-next-line no-param-reassign
      authRole.id = foundRole.id;
    }
  });
}
