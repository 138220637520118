



































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { BasicSelect } from 'vue-search-select';
import {
  Component, PropSync,
  Prop,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DetailedUserData } from '@/store/users/types';
import { DetailedUser } from '@/auth/auth0Types';
import { ProjectListing } from '@/store/project/types';
import { ProjectActions } from '@/store/project/actions';
import {
  getRoleString, getRolesArray, AuthUserRoles, getRoleId,
} from '@/auth/roles';
import IntegrityTable, { FillFunctionContainer } from '@/components/IntegrityTable/IntegrityTable.vue';
import IntegrityDelete from '@/components/IntegrityDelete/IntegrityDelete.vue';
import { UserPermission } from '@/store/userpermissions/types';
import { uuid } from 'vue-uuid';
import ProjectList from './ProjectList.vue';
import AdminPanelMixin from '../AdminPanelMixin.vue';

const projectModule = namespace('project');

// eslint-disable-next-line no-shadow
export enum UserCondition {
  INACTIVE = 'Inactive',
  PENDING = 'Pending',
  ACTIVE = 'Active',
}

@Component({
  components: {
    BasicSelect,
    IntegrityTable,
    IntegrityDelete,
    ProjectList,
  },
})
export default class ManageUsers extends AdminPanelMixin {
  @Prop() readonly search: string;

  @PropSync('selectedItems') synchedSelectedItems: Array<any>;

  @PropSync('userData') synchedUserData: Array<{
    role: any,
    auth0Id: any,
    detailedUserData: any,
    detailedUser: any,
    isExpanded: boolean
    visible: boolean
    resetDialog: boolean,
    guid?: string,
    fullName?: string
  }>;

  @Prop() userDataFiltered: Array<{
    role: any,
    auth0Id: any,
    detailedUserData: any,
    detailedUser: any,
    isExpanded: boolean
    visible: boolean
    resetDialog: boolean,
    guid?: string,
    fullName?: string
  }>;

  authUsers = [];

  selectedTab = 0;

  isExpanded = [];

  expanded = [];

  showSnackbar = false as boolean;

  snackbarColor = '' as string;

  snackbarMessage = '' as string;

  applyDark = false;

  rolesInfo: Array<{id: string, name: string}> = [];

  inTimeout = true;

  @projectModule.State('allProjectsData') allProjects:
    | ProjectListing[]
    | undefined;

  @projectModule.Action(ProjectActions.FETCH_ALL_PROJECTS_DATA)
  fetchAllProjectsData;

  allHeaders = [
    {
      text: 'Users',
      value: 'fullName',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Email',
      value: 'filledEmail',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Role',
      value: 'role',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Last Active',
      value: 'filledLastActive',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Status',
      value: 'userStatus',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Edit',
      value: 'edit',
      sortable: false,
      filterable: false,
    },
    {
      text: 'Delete',
      value: 'delete',
      sortable: false,
      filterable: false,
    },
    {
      text: 'Password Reset',
      value: 'reset',
      sortable: false,
      filterable: false,
    },
  ];

  matchFilters = [
    {
      header: 'fullName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'filledEmail',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'role',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'filledLastActive',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'userStatus',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'delete',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'reset',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  resetPasswordDialog(item: any): void {
    const dialogState = this.userDataFiltered.find((u) => u.guid === item.guid).resetDialog;
    this.userDataFiltered.find((u) => u.guid === item.guid).resetDialog = !dialogState;
  }

  editPage(userGuid: any): void {
    const user = this.userDataFiltered.find((u) => u.guid === userGuid);
    window.location.href = this.$router.resolve({
      name: 'editUser',
      params: { id: user.detailedUserData.guid },
    }).href;
  }

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'guid',
          functionVariables: [],
          fillFunction:
            function fillGuid(
              item: any,
            ): string {
              return item.detailedUserData.guid;
            },
        },
        {
          headerValue: 'fullName',
          functionVariables: [],
          fillFunction:
            function fillFullName(
              item: any,
            ): string {
              return `${item.detailedUserData.firstname} ${item.detailedUserData.lastname}`;
            },
        },
        {
          headerValue: 'filledEmail',
          functionVariables: [],
          fillFunction: function fillEmail(item: any): string {
            return item.detailedUserData.email;
          },
        },
        {
          headerValue: 'role',
          functionVariables: [AuthUserRoles],
          fillFunction: function fillRoleString(item: any, authUserRoles: any[]): string {
            const role = item.role[0];
            if (role == null || role === '') {
              return '';
            }
            const roleObj = authUserRoles.find((r) => r.id === role);
            return roleObj === undefined ? 'ROLE NOT FOUND' : roleObj.name;
          },
        },
        {
          headerValue: 'filledLastActive',
          functionVariables: [this.authUsers],
          fillFunction: function getLastActiveString(item: any, authUsers: any[]): string {
            const dbUser = item.detailedUserData;
            // eslint-disable-next-line no-nested-ternary, no-unreachable
            const user: DetailedUser = dbUser.useridentity != null && dbUser.useridentity !== ''
              ? authUsers.find(
                (value) => value.userId === dbUser.useridentity,
              ) != null
                ? authUsers.find((value) => value.userId === dbUser.useridentity)
                : null
              : null;

            const lastLogin = user != null && user.lastLogin != null ? user.lastLogin : '';

            const currentDate: number = Date.now();
            const userDate = new Date(lastLogin).getTime();
            const dateDifference: Date = new Date(currentDate - userDate);
            const years = dateDifference.getUTCFullYear() - 1970;
            const months = dateDifference.getUTCMonth();
            const days = dateDifference.getUTCDate();

            // eslint-disable-next-line no-restricted-globals
            if (isNaN(years) || isNaN(months) || isNaN(days)) {
              return 'Unknown';
            }

            if (years > 0) {
              return `${years} year${years > 1 ? 's' : ''} ago`;
            }
            if (months > 0) {
              return `${months} month${months > 1 ? 's' : ''} ago`;
            }
            if (days >= 7) {
              const weeks = Math.floor(days / 7);
              return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
            }

            return `${days} day${days > 1 ? 's' : ''} ago`;
          },
        },
        {
          headerValue: 'userStatus',
          functionVariables: [this.allDBUsers],
          fillFunction: this.statusTextFunction,
        },
      ],
    };
    return returnValue;
  }

  get headers(): any[] {
    let list = this.allHeaders;
    if (!this.hasPermissionAdminDeleteUser) {
      list = list.filter((item) => item.value !== 'delete');
    }
    if (!this.hasPermissionAdminResetUserPassword) {
      list = list.filter((item) => item.value !== 'reset');
    }
    return list;
  }

  /**
   * @returns true if the user has the permission ADMIN_USER_SECTION_DELETEUSER
   */
  get hasPermissionAdminDeleteUser(): boolean {
    return this.hasPermission(UserPermission.ADMIN_USER_SECTION_DELETEUSER);
  }

  /**
   * @returns true if the user has the permission ADMIN_USER_SECTION_RESETUSERPASSWORD
   */
  get hasPermissionAdminResetUserPassword(): boolean {
    return this.hasPermission(UserPermission.ADMIN_USER_SECTION_RESETUSERPASSWORD);
  }

  /**
   * @returns true if the user has the permission ADMIN_USER_SECTION_EDIT_PROJECT_ACCESS_DATES
   */
  get hasPermissionAdminSetDateRangeForActivation(): boolean {
    return this.hasPermission(UserPermission.ADMIN_USER_SECTION_EDIT_PROJECT_ACCESS_DATES);
  }

  getStatusColor(user: DetailedUser): string {
    switch (this.getUserCondition(user)) {
      case UserCondition.INACTIVE: return '#f9d4d4';
      case UserCondition.PENDING: return '#d2e4f6';
      case UserCondition.ACTIVE: return '#cae0d7';
      default: return '';
    }
  }

  getStatusTextColor(user: DetailedUser): string {
    switch (this.getUserCondition(user)) {
      case UserCondition.INACTIVE: return '#e61e25';
      case UserCondition.PENDING: return '#086699';
      case UserCondition.ACTIVE: return '#01734b';
      default: return '';
    }
  }

  get statusTextFunction(): any {
    return function getStatusText(item: any, allDBUsers: DetailedUserData[]): string {
      const userDB = allDBUsers.find((u) => u.useridentity === item.detailedUser.userId);
      if (!item.detailedUser || item.detailedUser.userId === null || !userDB?.isActive) {
        return 'Inactive';
      } if (item.detailedUser.lastLogin === '') {
        return 'Pending';
      }
      return 'Active';
    };
  }

  getUserCondition(detailedUser: DetailedUser): UserCondition {
    const userDB = this.allDBUsers.find((u) => u.useridentity === detailedUser.userId);
    if (!detailedUser || detailedUser.userId === null || !userDB?.isActive) {
      return UserCondition.INACTIVE;
    }
    if (detailedUser.lastLogin === '') {
      return UserCondition.PENDING;
    }
    return UserCondition.ACTIVE;
  }

  async deleteUser(userData: any): Promise<boolean> {
    const user = userData.detailedUserData;
    try {
      const auth0UserId = user.useridentity;

      const auth0UserDeleted = await this.$auth.deleteUser(auth0UserId);
      await this.deleteUserAction(user.guid);
      if (!auth0UserDeleted) {
        return false;
      }
    } catch (e) {
      return false;
    }
    // eslint-disable-next-line no-param-reassign
    userData.visible = false;
    // eslint-disable-next-line no-param-reassign
    userData.detailedUserData.isExpanded = false;
    this.$forceUpdate();
    return true;
  }

  deleteUserInterface(guid: string): void {
    this.userDataFiltered.find(
      (user) => user.detailedUserData.guid === guid,
    ).visible = false;
  }

  async mounted(): Promise<void> {
    this.fetchAllProjectsData();
    this.updateAllUsers();
    this.$root.$on('clearUserTableFilters', () => {
      (this.$refs.usersTable as IntegrityTable).clearAllMatchFilters();
    });
  }

  uniqueValues(value, index, arr) {
    return arr.indexOf(value) === index;
  }

  roleIdFromPermissions(permissions: UserPermission[]): string[] {
    const retVal = [];
    const permissionStrings = permissions.map((p) => p.split(':')[1]);
    AuthUserRoles.forEach((role) => {
      const roleName = role.name.replaceAll(' ', '');
      if (permissionStrings.includes(roleName)) {
        retVal.push(role.id);
      }
    });
    return retVal;
  }

  // @Watch('selectedItems')
  // async onSelectedItemsChange(): Promise<void> {
  //   if (this.synchedSelectedItems[0]) {
  //     this.canManage = await this.getCanManage(this.synchedSelectedItems[0].role);
  //   }
  // }

  async updateAllUsers(): Promise<void> {
    this.synchedUserData = [];
    this.authUsers = await this.$auth.getAllUsers();
    const roleUsers = await this.$auth.getAllUserRoles();
    const visibleRoles = this.roleIdFromPermissions(this.userPermissions.filter((perm) => perm.includes('AdminCanManage')));

    setTimeout(() => {
      this.inTimeout = true;
      const currentUser = this.allDBUsers.find((user) => {
        if (user.useridentity) {
          return user.useridentity.replace('auth0|', '') === this.$auth.user.id;
        }
        return false;
      });

      const filteredUsers = currentUser != null ? this.deepCopyFunction(this.allDBUsers) : [];

      if (currentUser != null) {
        filteredUsers.splice(
          filteredUsers.indexOf(
            filteredUsers.find((fU) => fU.guid === currentUser.guid),
          ),
          1,
        );
      }
      filteredUsers.forEach(async (user) => {
        let visible = true;
        if (user.useridentity != null && user.useridentity !== '') {
          const info = this.authUsers.find(
            (value) => value.userId === user.useridentity,
          );
          if (info == null) {
            this.synchedUserData.push({
              role: null,
              auth0Id: null,
              detailedUserData: user,
              detailedUser: null,
              isExpanded: false,
              visible: false,
              resetDialog: false,
            });
          } else if (info.userId != null && info.userId !== '') {
            let role = [];
            try {
              roleUsers.find((value) => {
                if (Array.isArray(value)) {
                  value.forEach((val) => {
                    if (val.userId === user.useridentity) {
                      role.push(val.role);
                    }
                  });
                } else if (value.userId === user.useridentity) {
                  role.push(value.role);
                }
                return '';
              });
              // check if current user can view user's role
              if (role.length > 0 && !visibleRoles.find((r) => role.includes(r))) {
                visible = false;
              }
            } catch (e) {
              role = [];
            }

            this.synchedUserData.push({
              role,
              auth0Id: info.userId,
              detailedUserData: user,
              detailedUser: info,
              isExpanded: false,
              visible,
              resetDialog: false,
            });
          } else {
            this.synchedUserData.push({
              role: null,
              auth0Id: null,
              detailedUserData: user,
              detailedUser: null,
              isExpanded: false,
              visible: false,
              resetDialog: false,
            });
          }
        } else {
          this.synchedUserData.push({
            role: null,
            auth0Id: null,
            detailedUserData: user,
            detailedUser: null,
            isExpanded: false,
            visible: false,
            resetDialog: false,
          });
        }
      });
      this.inTimeout = false;
    }, 1000);
  }

  getRoleString(role: string): string {
    return role && role !== '' ? getRoleString(role) : '';
  }

  getRolesArray(item): string[] {
    return getRolesArray(item.role);
  }

  getRoleId(roleName: string): string {
    return roleName && roleName !== '' ? getRoleId(roleName) : '';
  }

  resetPassword(userGuid: string): void {
    const user = this.userDataFiltered.find((u) => u.guid === userGuid);
    const { email } = user.detailedUserData;

    this.$auth.sendPasswordReset(email);
    this.snackbarColor = 'green';
    this.snackbarMessage = 'Reset Password E-Mail Sent Successfully';
    this.showSnackbar = true;
  }

  deepCopyFunction(inObject): any {
    let outObject;
    let value;
    let key;

    if (typeof inObject !== 'object' || inObject === null) {
      return inObject; // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    // eslint-disable-next-line prefer-const
    outObject = Array.isArray(inObject) ? [] : {};

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (key in inObject) {
      value = inObject[key];

      // Recursively (deep) copy for nested objects, including arrays
      outObject[key] = this.deepCopyFunction(value);
    }

    return outObject;
  }

  get allDBUsers(): DetailedUserData[] {
    if (this.allUsers && this.inactiveDetailedUserData) {
      return [...this.allUsers, ...this.inactiveDetailedUserData];
    }
    return this.allUsers;
  }
}
