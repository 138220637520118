















































































import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import { DetailedUserData } from '@/store/users/types';
import { PhoneInfo } from '@/components/IntegrityPhoneNumberInput/types';
import { OrganizationData } from '@/store/organizations/types';
import { UserPermission } from '@/store/userpermissions/types';
import IntegrityPhoneNumberInput from '../../IntegrityPhoneNumberInput/IntegrityPhoneNumberInput.vue';
import AdminPanelMixin from '../AdminPanelMixin.vue';
import NewUserPopup from '../NewUserPopup.vue';
import CustomerDialogSelectOrganization from './CustomerDialogSelectOrganization.vue';
import {
  getRoleId,
  AuthUserRoles,
} from '../../../auth/roles';

@Component({
  components: {
    NewUserPopup,
    IntegrityPhoneNumberInput,
    CustomerDialogSelectOrganization,
  },
})
export default class CustomerEditDialog extends AdminPanelMixin {
  @PropSync('show') showDialog: boolean;

  @Prop() saveCustomerData;

  @Prop({ default: false }) customerLoading: boolean;

  @Prop() title: string;

  @Prop({
    default: () => ({
      customerName: '',
      address: '',
      systemManager: null,
      email: '',
      phoneNumber: null,
      organization: null,
      sameAsOrganization: false,
    }),
  })
  editItem: {
    guid: string | undefined;
    customerName: string;
    address: string;
    systemManager: DetailedUserData | undefined;
    email: string;
    phoneNumber: PhoneInfo | undefined;
    organization: OrganizationData | undefined;
    sameAsOrganization: boolean;
  };

  valid = false as boolean;

  @Watch('editItem.sameAsOrganization')
  onSameAsOrganizationChange(): void {
    this.setCustomerNameAsOrganizationName();
  }

  @Watch('editItem.organization')
  onOrganizationChange(): void {
    this.setCustomerNameAsOrganizationName();
  }

  /**
   * @description sets customer to organization name
   * @remarks only sets value if sameAsOrganization is true and there is an organization set
   */
  setCustomerNameAsOrganizationName(): void {
    if (this.editItem.sameAsOrganization && this.editItem.organization) {
      this.editItem.customerName = this.editItem.organization.name;
    }
  }

  /**
   * @description Calls endpoint to post or patch an customer and fetches new customer data.
   */
  async saveCustomer(): Promise<void> {
    const phoneCode = this.editItem.phoneNumber.isInternational
      ? `${this.editItem.phoneNumber.phoneCode} `
      : '';

    const customerSaveObject = {
      guid: this.editItem.guid,
      name: this.editItem.customerName,
      address: this.editItem.address,
      systemOwnerGuid: this.editItem.systemManager.guid,
      email: this.editItem.email,
      phoneNumber: `${phoneCode}${this.editItem.phoneNumber.phone}`,
      organizationGuid: this.editItem.organization.guid,
    };

    // check if org system owner matches the customer system manager
    // if they do, leave it alone, otherwise make sure the system manager gets system manager role
    if (this.editItem.organization.systemAdmin !== this.editItem.systemManager.guid) {
      // add new roles
      const newRole = getRoleId('System Manager');
      const matchUser = this.allUsers.find((u) => u.guid === this.editItem.systemManager.guid);
      // TODO: T1-6806 was created to handle the match role not using the role from auth0/
      // Cannot delete auth0 role from user with no associated project
      if (matchUser?.role != null && matchUser.role.length > 0) {
        const foundUserRole = AuthUserRoles
          .find((role) => role.guid === matchUser.role[0].roleGuid);
        if (foundUserRole?.id != null) {
          await this.$auth.deleteRoles(
            matchUser.useridentity, [foundUserRole.id],
          );
        }
      }
      const addResults = await this.$auth.addRoles(
        matchUser.useridentity, [newRole],
      );
      if (addResults === 'false') {
        console.error('Unable to Add System Manager Role to User');
      }
    }

    await this.saveCustomerData(customerSaveObject);
    await this.fetchCustomerData();
    this.$emit('customerSaved', customerSaveObject);
    this.showDialog = false;
  }

  /**
   * @returns true if the user has the permission ADMIN_ORGANIZATION_SECTION_CREATEANORGANIZATION
   */
  get hasPermissionAdminCreateAnOrganization(): boolean {
    return this.hasPermission(UserPermission.ADMIN_ORGANIZATION_SECTION_CREATEANORGANIZATION);
  }
}
