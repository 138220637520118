



















































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { BasicSelect } from 'vue-search-select';
import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AdminActions } from '@/store/admin/actions';
import { UserRole } from '@/store/users/types';
import { ProjectListing } from '@/store/project/types';
import IntegrityTable, { FillFunctionContainer, processDateWithTableObject } from '@/components/IntegrityTable/IntegrityTable.vue';
import IntegrityDelete from '@/components/IntegrityDelete/IntegrityDelete.vue';
import { UserPermission } from '@/store/userpermissions/types';
import { uuid } from 'vue-uuid';
import { CustomerData } from '@/store/customers/types';
import AdminPanelMixin, { EditUserData } from '../AdminPanelMixin.vue';

const adminModule = namespace('admin');

@Component({
  components: {
    BasicSelect,
    IntegrityTable,
    IntegrityDelete,
  },
})
export default class ProjectList extends AdminPanelMixin {
  @PropSync('user') readonly synchedSelectedUser: EditUserData;

  @Prop() readonly projects!: ProjectListing[] | undefined;

  @Prop() readonly search!: string;

  @Prop() readonly hideDefaultFooter!: boolean;

  @Prop({ default: true }) readonly patchUserOnSave: boolean;

  @adminModule.Action(AdminActions.DELETE_USER_ROLE) deleteUserRoleAction;

  @adminModule.Action(AdminActions.PATCH_USER_ROLE) patchUserRoleAction;

  @adminModule.State('loading') loading;

  projectsCopy =
    this.projects == null ? ([] as ProjectListing[]) : this.projects;

  editDialogVisible = false;

  startDateMenu = false as boolean;

  endDateMenu = false as boolean;

  startDate = null as string | undefined;

  endDate = null as string;

  userRoleToEdit = {} as UserRole;

  userRoleToDelete = {} as UserRole;

  snackbarMessage = '';

  snackbarColor = '';

  snackbarVisible = false;

  allHeaders = [
    {
      text: 'Project Name',
      value: 'projectName',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Project Number',
      value: 'projectNumber',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Customer',
      value: 'projectCustomer',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Access Start Date',
      value: 'scheduledStartTime',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Access End Date',
      value: 'scheduledEndTime',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'left',
      sortable: false,
    },
  ];

  matchFilters = [
    {
      header: 'projectName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'projectNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'projectCustomer',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'scheduledStartTime',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'scheduledEndTime',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  mounted(): void {
    if (!this.customerDataList) {
      this.fetchCustomerData();
    }
  }

  @Watch('projects')
  onProjectChange(): void {
    if (this.projects != null) {
      this.projectsCopy = this.projects;
    }
  }

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'scheduledStartTime',
          functionVariables: ['scheduledStartTime'],
          fillFunction: processDateWithTableObject,
        },
        {
          headerValue: 'scheduledEndTime',
          functionVariables: ['scheduledEndTime'],
          fillFunction: processDateWithTableObject,
        },
        {
          headerValue: 'projectName',
          functionVariables: [this.projectsCopy],
          fillFunction: function fillProjectName(
            item: UserRole,
            projectsCopy: ProjectListing[],
          ): string {
            if (projectsCopy != null && projectsCopy.length > 0) {
              const foundProject = projectsCopy.find(
                (value) => value.guid === item.projectGuid,
              );
              if (foundProject) {
                return foundProject.name;
              }
            }
            return '';
          },
        },
        {
          headerValue: 'projectNumber',
          functionVariables: [this.projectsCopy],
          fillFunction: function fillProjectNumber(
            item: UserRole,
            projectsCopy: ProjectListing[],
          ): string {
            if (projectsCopy != null && projectsCopy.length > 0) {
              const foundProject = projectsCopy.find(
                (value) => value.guid === item.projectGuid,
              );
              if (foundProject) {
                return foundProject.projectNumber;
              }
            }
            return '';
          },
        },
        {
          headerValue: 'projectCustomer',
          functionVariables: [this.projectsCopy, this.customerDataList],
          fillFunction: function fillProjectCustomer(
            item: UserRole,
            projectsCopy: ProjectListing[],
            customerDataList: CustomerData[],
          ): string {
            if (projectsCopy != null && projectsCopy.length > 0) {
              const foundProject = projectsCopy.find(
                (value) => value.guid === item.projectGuid,
              );
              if (foundProject) {
                return customerDataList?.find(
                  (cust) => cust.guid === foundProject.customerGuid,
                )?.name ?? '';
              }
            }
            return '';
          },
        },
      ],
    };
    return returnValue;
  }

  get filteredRoles(): any[] {
    return this.synchedSelectedUser?.detailedUserData?.role != null
      ? this.synchedSelectedUser.detailedUserData.role
      : undefined;
  }

  get headers(): any[] {
    let list = this.allHeaders;
    if (!this.hasPermissionAdminDeleteUser) {
      list = list.filter((item) => item.value !== 'delete');
    }
    if (!this.hasPermissionAdminSetDateRangeForActivation) {
      list = list.filter((item) => item.value !== 'edit');
    }
    return list;
  }

  editDialog(userRoleToEdit: UserRole): void {
    this.startDate = this.getDateString(userRoleToEdit.startDate);
    this.endDate = this.getDateString(userRoleToEdit.endDate);
    this.userRoleToEdit = userRoleToEdit;
    this.editDialogVisible = true;
    this.$forceUpdate();
  }

  async deleteUserRole(userRoleToDelete: UserRole): Promise<boolean> {
    try {
      const foundUserRoleIndex = this.synchedSelectedUser.detailedUserData.role.findIndex(
        (value) => value.projectGuid === userRoleToDelete.projectGuid,
      );
      if (foundUserRoleIndex > -1) {
        this.synchedSelectedUser.detailedUserData.role.splice(
          foundUserRoleIndex,
          1,
        );
        if (this.synchedSelectedUser.detailedUserData.role.length <= 0) {
          this.$emit(
            'deleteUser',
            this.synchedSelectedUser.detailedUserData.guid,
          );
        }
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;
  }

  async editUserRole(userRoleToEdit: UserRole): Promise<void> {
    // eslint-disable-next-line no-param-reassign
    userRoleToEdit.startDate = !this.startDate
      ? null
      : new Date(this.startDate);
    // eslint-disable-next-line no-param-reassign
    userRoleToEdit.endDate = !this.endDate ? null : new Date(this.endDate);

    try {
      if (this.patchUserOnSave) {
        await this.patchUserRoleAction(userRoleToEdit);
      }
      const userRoleIndex = this.synchedSelectedUser.detailedUserData.role.findIndex(
        (r) => r.guid === userRoleToEdit.guid,
      );
      this.synchedSelectedUser.detailedUserData.role.splice(
        userRoleIndex,
        1,
        userRoleToEdit,
      );

      this.snackbarMessage = 'User role edited';
      this.snackbarColor = 'green';
    } catch (e) {
      this.snackbarMessage = (e as Error).message;
      this.snackbarColor = '#e61e25';
    }

    this.snackbarVisible = true;
    this.editDialogVisible = false;
  }

  getDateString(date: Date): string {
    return !date ? null : new Date(date).toISOString().substr(0, 10);
  }

  /**
   * @returns true if the user has the permission ADMIN_USER_SECTION_DELETEUSER
   */
  get hasPermissionAdminDeleteUser(): boolean {
    return this.hasPermission(UserPermission.ADMIN_USER_SECTION_DELETEUSER);
  }

  /**
   * @returns true if the user has the permission ADMIN_USER_SECTION_EDIT_PROJECT_ACCESS_DATES
   */
  get hasPermissionAdminSetDateRangeForActivation(): boolean {
    return this.hasPermission(UserPermission.ADMIN_USER_SECTION_EDIT_PROJECT_ACCESS_DATES);
  }
}
