
















































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component, PropSync, Watch } from 'vue-property-decorator';
import { OrganizationData } from '@/store/organizations/types';
import { CustomerData } from '@/store/customers/types';
import AdminPanelMixin, { EditUserData } from '../AdminPanelMixin.vue';
import CustomerDialogMultiSelectCustomer from '../Customers/CustomerDialogMultiSelectCustomer.vue';

@Component({
  components: {
    CustomerDialogMultiSelectCustomer,
  },
})
export default class AddCustomerButton extends AdminPanelMixin {
  @PropSync('selectedUser') readonly synchedSelectedUser: EditUserData;

  addCustomerDialog = false;

  userCustomers = null;

  @Watch('addCustomerDialog')
  onAddCustomerDialogChange(): void {
    if (this.addCustomerDialog) {
      this.userCustomers = this.synchedSelectedUser.detailedUserData.customerGuids
        .map((guid) => this.customerDataList.find((cust) => cust.guid === guid));
    }
  }

  get userOrganizations(): OrganizationData[] {
    if (!this.organizationDataList) {
      return [];
    }
    return this.synchedSelectedUser.detailedUserData.organizationGuids.map(
      (guid) => this.organizationDataList.find((org) => org.guid === guid),
    );
  }

  saveAddCustomer(): void {
    this.synchedSelectedUser.detailedUserData.customerGuids = (
      this.userCustomers as CustomerData[]
    ).map((x) => x.guid);
    this.addCustomerDialog = false;
  }
}
