
























































































import {
  Component, Prop, VModel, Watch,
} from 'vue-property-decorator';
import { DetailedUserData } from '@/store/users/types';
import { User } from '@/store/admin/types';
import { SYSTEM_MANAGER_GUID } from '@/auth/roles';
import { namespace } from 'vuex-class';
import { AdminActions } from '@/store/admin/actions';
import AdminPanelMixin from './AdminPanelMixin.vue';

const adminModule = namespace('admin');

@Component({})
export default class NewUserPopup extends AdminPanelMixin {
  @VModel() modelValue!: DetailedUserData | undefined;

  @adminModule.Action(AdminActions.POST_NEW_USER) postUserData;

  @Prop({ default: 'System Manager' }) readonly label: string;

  showNewUserDialog = false;

  systemManager = '';

  firstName = '';

  lastName = '';

  email = '';

  systemManagerSearch = null;

  valid = false as boolean;

  mounted(): void {
    if (this.modelValue) {
      this.setLocalValues();
    }
  }

  @Watch('modelValue')
  onModelValueChange(): void {
    this.setLocalValues();
  }

  /**
   * @description Sets all local values from the modelValue.
   */
  setLocalValues(): void {
    this.firstName = this.modelValue?.firstname ?? '';
    this.lastName = this.modelValue?.lastname ?? '';
    const searchVal = this.modelValue ? `${this.firstName} ${this.lastName}` : '';
    this.systemManager = searchVal;
    this.systemManagerSearch = searchVal;
  }

  @Watch('showNewUserDialog')
  onShowNewUserDialogChange(): void {
    if (!this.showNewUserDialog) {
      // If closing the dialog, then reset the search to the current modelValue
      this.setLocalValues();
    }
  }

  /**
   * @description Checks for a new user in the combobox
   * @remarks If an existing user is selected, then select them. If not, then open up the popup
   */
  checkforNewUserAdd(): void {
    if (!this.systemManager) {
      return;
    }
    const splitName = this.systemManager.split(' ');

    // eslint-disable-next-line prefer-destructuring
    this.firstName = splitName[0];
    // eslint-disable-next-line prefer-destructuring
    this.lastName = splitName.length > 1 ? splitName[1] : '';

    const foundValue = this.allUsers.find(
      (u) => u.firstname === this.firstName && u.lastname === this.lastName,
    );

    if (!foundValue) {
      this.showNewUserDialog = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.systemManagerComboBox as any).isFocused = false;
    } else {
      this.modelValue = foundValue;
    }
  }

  /**
   * @description Save user to auth0 and db with a system Manager role.
   */
  async saveUser(): Promise<void> {
    const user = {} as User;
    user.Firstname = this.firstName;
    user.Lastname = this.lastName;
    user.Email = this.email;
    user.Nickname = `${this.firstName} ${this.lastName}`;
    user.Role = SYSTEM_MANAGER_GUID;

    const auth0User = await this.$auth.createUser(user);
    await this.postUserData(auth0User);
    await this.fetchAllDetailedUserData();

    this.modelValue = this.allUsers.find(
      (u) => u.firstname === this.firstName && u.lastname === this.lastName,
    );

    this.showNewUserDialog = false;
  }

  /**
   * @description Get a list of all user's full names.
   * @returns {string[]} list of user full names
   */
  get usersFullNames(): string[] {
    if (!this.allUsers) {
      return [];
    }
    return this.allUsers
      .map((item) => `${item.firstname} ${item.lastname}`)
      .sort();
  }
}
