












































































































import {
  Component, Prop,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AssignmentActions } from '@/store/assignments/actions';
import { AICodingWorkOrder } from '@/store/assignments/types';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { UserPermission } from '@/store/userpermissions/types';
import { uuid } from 'vue-uuid';
import { AuthUserRoles } from '@/auth/roles';
import { TableMode } from '../../components/ExportDataPopout/types';
import { DataTableHeader } from '../../components/AssetTable/types';
import IntegrityTable, {
  AdditionalFilterFunction, FillFunctionContainer, FilterFunction, processDate,
} from '../../components/IntegrityTable/IntegrityTable.vue';
import IntegrityDelete from '../../components/IntegrityDelete/IntegrityDelete.vue';

const assignmentModule = namespace('assignments');

@Component({
  components: {
    IntegrityTable,
    IntegrityDelete,
  },
})
export default class WinCanWorkOrder extends UserPermissionsMixin {
  @assignmentModule.Action(AssignmentActions.GET_AICODING_WORKORDERS) getAiWorkorders;

  @assignmentModule.Action(AssignmentActions.PATCH_AICODING_WORKORDERS) patchAiWorkorders;

  @assignmentModule.State('getAICodingWorkOrderError') loadError : string | undefined;

  @assignmentModule.State('getAICodingWorkOrderLoading') loadingData : boolean;

  @assignmentModule.State('getAICodingWorkOrderData') tableData : AICodingWorkOrder[];

  @assignmentModule.State('patchAICodingWorkOrderError') patchError : string | undefined;

  @assignmentModule.State('patchAICodingWorkOrderLoading') patchLoading : boolean;

  @assignmentModule.State('patchAICodingWorkOrderData') patchData;

  @Prop() readonly id!: string;

  projectGuidList: string[] = [];

  activeTab = 0;

  roles: string[] = [];

  isPM = false;

  isSysAdmin = false;

  isReportingTech = false;

  search = '';

  snack = false;

  snackColor = 'black';

  snackText = '';

  tableMode = TableMode.NORMAL;

  expandTableLocal = false;

  tableModel = [];

  blankMatchFilters = {};

  manholeHeaders: DataTableHeader[] = [
    {
      text: 'Asset Name', value: 'assetName', sortable: true, filterable: true, class: 'sticky', cellClass: 'sticky', editable: true, stopFilterValueGeneration: true,
    },
    {
      text: 'Status', value: 'status', sortable: true, filterable: true,
    },
    {
      text: 'Deployment', value: 'deployment', sortable: true, filterable: true,
    },
    {
      text: 'Total Footage', value: 'totalFootage', sortable: true, filterable: true,
    },
    {
      text: 'Direction', value: 'direction', sortable: true, filterable: true, editable: true,
    },
    {
      text: 'Deployment Date', value: 'dateTime', sortable: true, filterable: true, editable: true,
    },
  ];

  pipeHeaders: DataTableHeader[] = [
    {
      text: 'Asset Name', value: 'assetName', sortable: true, filterable: true, class: 'sticky', cellClass: 'sticky', editable: true,
    },
    {
      text: 'Status', value: 'status', sortable: true, filterable: true,
    },
    {
      text: 'Deployment', value: 'deployment', sortable: true, filterable: true,
    },
    {
      text: 'Total Footage', value: 'totalFootage', sortable: true, filterable: true,
    },
    {
      text: 'Direction', value: 'direction', sortable: true, filterable: true, editable: true,
    },
    {
      text: 'Deployment Date', value: 'dateTime', sortable: true, filterable: true, editable: true,
    },
  ];

  matchFilters = [
    {
      header: 'assetName', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'status', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'deployment', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'totalFootage', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'direction', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'dateTime', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
  ]

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'dateTime',
          functionVariables: [processDate.toString()],
          fillFunction: function fillDateTime(
            item: AICodingWorkOrder,
            processDateFunctionString: string,
          ): string {
          // eslint-disable-next-line no-new-func
            return new Function(`return ${processDateFunctionString};`)()(item.dateTime);
          },
        },
      ],
    };
    return returnValue;
  }

  get additionalFilterFunctions(): AdditionalFilterFunction {
    const filterFunctions: FilterFunction[] = [
      {
        functionVariables: [['Manhole', 'Line Segment'], this.activeTab],
        filterFunction: function isAssetType(item, typeStrings, activeTab) {
          return item.type === typeStrings[activeTab];
        },
      },
    ];
    return {
      updateKey: uuid.v4(),
      filterFunctions,
    };
  }

  displayHeaders: DataTableHeader[][] = [
    this.manholeHeaders,
    this.pipeHeaders,
  ];

  tabOptions = [
    { name: 'Manhole', value: 'Manhole' },
    { name: 'Line Segment', value: 'Line Segment' },
  ]

  get editPermissions(): boolean | undefined {
    return this.isPM || this.isSysAdmin || this.isReportingTech;
  }

  get featureHeaders(): DataTableHeader[] {
    return this.displayHeaders[this.activeTab];
  }

  get filteredTableData(): AICodingWorkOrder[] {
    if (!this.tableData) {
      return undefined;
    }
    return this.tableData;
  }

  get showSelect(): boolean {
    return this.isEditTable();
  }

  get hasPermissionAICommon(): boolean {
    return this.hasPermission(UserPermission.AI_COMMON);
  }

  async getRoles(): Promise<string[]> {
    return this.$auth.getRoles(`auth0|${this.$auth.user.id}`);
  }

  async mounted(): Promise<void> {
    try {
      this.projectGuidList = (JSON.parse(this.id) as string[]);
    } catch {
      this.projectGuidList = [this.id];
    }
    this.getAiWorkorders(this.id);
    this.expandTableLocal = false;

    if (this.$auth.user.id !== null) {
      const PROJECT_MANAGER = AuthUserRoles.find((role) => role.name === 'Project Manager').id;
      const SUPER_USER = AuthUserRoles.find((role) => role.name === 'Super User').id;
      const SYS_ADMIN_AUTH0 = AuthUserRoles.find((role) => role.name === 'System Admin').id;
      const REPORTING_TECH_AUTH0 = AuthUserRoles.find((role) => role.name === 'Reporting Technician').id;
      this.roles = await this.getRoles();
      this.isPM = this.roles.includes(PROJECT_MANAGER) || this.roles.includes(SUPER_USER);
      this.isSysAdmin = this.roles.includes(SYS_ADMIN_AUTH0) || this.roles.includes(SUPER_USER);
      this.isReportingTech = this.roles.includes(REPORTING_TECH_AUTH0);
    }
    // Do not have the ai coding permission
    if (!this.hasPermissionAICommon) {
      this.goToErrorPage();
    }
  }

  isEditTable(): boolean {
    return this.tableMode === TableMode.EDIT;
  }

  toggleEdit(): void {
    this.expandTableLocal = true;
    this.tableMode = this.tableMode !== TableMode.EDIT ? TableMode.EDIT : TableMode.NORMAL;

    if (this.isEditTable()) {
      this.tableModel = [];
    } else {
      this.tableModel = [];
    }
  }

  async saveWorkOrder(workOrder: AICodingWorkOrder): Promise<void> {
    // Call edit API
    try {
      if (typeof workOrder.dateTime === 'string' || workOrder.dateTime instanceof String) {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(Date.parse(workOrder.dateTime as unknown as string))) {
          this.snack = true;
          this.snackColor = '#e61e25';
          this.snackText = 'Entered date/time is not valid';
          this.$forceUpdate();
          return;
        }
      }
      await this.patchAiWorkorders(
        { projectGuid: this.projectGuidList[0], payload: [workOrder] },
      );
      if (this.patchData === 'Success') {
        // set snack bar
        this.snack = true;
        this.snackColor = 'green';
        this.snackText = 'Data saved';
        this.$forceUpdate();
      } else {
        this.snack = true;
        this.snackColor = '#e61e25';
        this.snackText = this.patchData === undefined || this.patchData === '' ? 'Data not saved' : this.patchData;
        this.$forceUpdate();
      }
    } catch {
      this.snack = true;
      this.snackColor = '#e61e25';
      this.snackText = 'Edit Failed';
      this.$forceUpdate();
    }
  }
}
