




















































































































































































































































































































































































































































































































































































































































/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProjectData } from '@/store/project/types';
import {
  AdvancedFilter, AssetData, AssetFilter, FilterPair,
} from '@/types';
import {
  Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import RouteSelect from '@/components/RouteSelect/RouteSelect.vue';
import { namespace } from 'vuex-class';
import { ProjectMutations } from '@/store/project/mutations';

import { RoutingActions } from '@/store/routing/actions';
import { SimpleRoutingData } from '@/store/routing/types';

const routingModule = namespace('routing');

const projectModule = namespace('project');

@Component({ components: { RouteSelect } })
export default class AssetFilters extends Vue {
  @projectModule.Mutation(ProjectMutations.SET_MAP_NEEDS_UPDATED) setMapNeedsUpdated;

  @PropSync('selectedMapFeatures') synchedSelectedMapFeatures;

  @PropSync('assetData') synchedAssetData: AssetData[];

  @PropSync('filters') synchedFilters: AssetFilter;

  @Prop() readonly project: ProjectData;

  @Prop() readonly projectGuid: string;

  @routingModule.Action(RoutingActions.FETCH_SIMPLE_ROUTING_DATA) fetchSimpleRoutingData;

  @routingModule.State('simpleRoutingData') routingData: SimpleRoutingData[];

  @routingModule.State('routingLoading') routingLoading: boolean;

  @Watch('synchedFilters', { deep: true })
  onFiltersChange(): void {
    this.applyFilters();
  }

  @Watch('synchedFilters.dateInspected', { deep: true })
  onDateInspectedChange(): void {
    if (!this.synchedFilters.dateInspected.includes('Custom')) {
      this.customFromDateMenu = false;
      this.customToDateMenu = false;
      this.synchedFilters.customFromDate = '';
      this.synchedFilters.customToDate = '';
    }
  }

  async onRoutingDataChange(): Promise<void> {
    await this.fetchSimpleRoutingData(this.projectGuid);
    this.isDisabled = this.routingData == null || this.routingData.length <= 0;
  }

  isDisabled = false;

  timeRequestMade = 0;

  showAllFilters = false;

  showAdvancedFilters = false;

  customFromDateMenu = false;

  customToDateMenu = false;

  advancedFilterOptions = [
    { text: 'Pipe Size' },
    { text: 'Material' },
  ] as AdvancedFilter[];

  comparisonOptions = ['Less than', 'Greater than', 'Equals'];

  selectedAdvancedFilter = {} as AdvancedFilter;

  showNewAdvancedFilter = false;

  showRoutes = false;

  get materialOptions(): string[] {
    const retVal = [];

    this.synchedAssetData.forEach((asset) => {
      if (asset.type === 'Line Segment') {
        if (retVal.findIndex((opt) => opt === asset.pipeMaterial) === -1) {
          retVal.push(asset.pipeMaterial);
        }
      }
    });

    return retVal;
  }

  get pipeSizeOptions(): string[] {
    const retVal = [];

    this.synchedAssetData.forEach((asset) => {
      if (asset.type === 'Line Segment') {
        if (retVal.findIndex((opt) => opt === asset.pipeSize) === -1) {
          retVal.push(asset.pipeSize);
        }
      }
    });

    retVal.sort((a, b) => {
      const aNum = parseFloat(a.split('"')[0]);
      const bNum = parseFloat(b.split('"')[0]);

      if (aNum < bNum) return -1;
      if (aNum > bNum) return 1;

      return 0;
    });

    return retVal;
  }

  get omScores(): string[] {
    const omScores = [];

    this.synchedAssetData.forEach((asset) => {
      if (asset.omQuick && !omScores.find((s) => s.value === asset.omQuick)) {
        omScores.push({ text: asset.omQuick, value: asset.omQuick });
      }
    });

    omScores.sort(this.sortQuickScore);

    return omScores;
  }

  get structuralScores(): string[] {
    const structScores = [];

    this.synchedAssetData.forEach((asset) => {
      if (asset.structuralQuick && !structScores.find((s) => s.value === asset.structuralQuick)) {
        structScores.push({ text: asset.structuralQuick, value: asset.structuralQuick });
      }
    });

    structScores.sort(this.sortQuickScore);

    return structScores;
  }

  get allFiltersOptions(): any {
    const options = [
      {
        header: 'Type',
        section: 'type',
        options: [
          { text: 'Line Segments', value: 'Line Segment' },
          { text: 'Manholes', value: 'Manhole' },
          { text: 'Laterals', value: 'Lateral' },
        ],
      },
      {
        header: 'Collection Status',
        section: 'collectionStatus',
        options: [
          {
            text: 'Uninspected',
            value: 'Uninspected',
            disabled: false,
          },
          {
            text: 'In Progress',
            value: 'Component Pending',
            disabled: false,
          },
          {
            text: 'Completed',
            value: 'Complete',
            disabled: false,
          },
          {
            text: 'Arrived',
            value: 'Arrived',
            disabled: false,
          },
          {
            text: 'Incomplete',
            value: 'Incomplete',
            disabled: false,
          },
        ],
      },
      {
        header: 'Score',
        section: 'score',
        options: [
          { text: '0', value: 0 },
          { text: '1', value: 1 },
          { text: '2', value: 2 },
          { text: '3', value: 3 },
          { text: '4', value: 4 },
          { text: '5', value: 5 },
          { text: 'Unscored', value: -1 },
        ],
      },
      {
        header: 'Platform',
        section: 'robot',
        options: [
          { text: 'Solo', value: 'Solo' },
          { text: 'Vertue', value: 'Vertue' },
          { text: 'P3D', value: 'P3D' },
          // { text: 'Respoder', value: 'Responder' },
          // { text: 'Solo MH', value: 'Solo MH' },
          // { text: 'Profiler', value: 'Profiler' },
          // { text: 'CCTV', value: 'CCTV' },
        ],
      },
      {
        header: 'O&M Score',
        section: 'omQuick',
        options: this.omScores,
      },
      {
        header: 'Structural Score',
        section: 'structuralQuick',
        options: this.structuralScores,
      },
    ];

    options.push({
      header: 'Reporting Status',
      section: 'reportingStatus',
      options: [
        { text: 'Uncoded', value: 'Uncoded' },
        { text: 'Ready For Coding', value: 'Ready For Coding' },
        { text: 'Do Not Code', value: 'Do Not Code' },
        { text: 'In Process', value: 'In Process' },
        { text: 'QA Required', value: 'QA Required' },
        { text: 'QA Complete', value: 'QA Complete' },
        { text: 'Delivered', value: 'Delivered' },
        { text: 'Could Not Code', value: 'Could Not Code' },
        { text: 'Inspection Incomplete', value: 'Inspection Incomplete' },
      ],
    });

    options.push({
      header: 'Date Inspected',
      section: 'dateInspected',
      options: [
        { text: 'Today', value: 'Today' },
        { text: 'Yesterday', value: 'Yesterday' },
        { text: 'This Week', value: 'This Week' },
        { text: 'This Month', value: 'This Month' },
        { text: 'Custom', value: 'Custom' },
      ],
    });

    return options;
  }

  get filtersActive(): boolean {
    return (
      this.synchedFilters.selectedAssets.length > 0
      || this.synchedFilters.type.length > 0
      || this.synchedFilters.collectionStatus.length > 0
      || this.synchedFilters.reportingStatus.length > 0
      || this.synchedFilters.dateInspected.length > 0
      || this.synchedFilters.score.length > 0
      || this.synchedFilters.robot.length > 0
      || this.synchedFilters.status.length > 0
      || this.synchedFilters.matchFilters.length > 0
      || this.synchedFilters.hiddenFilters.length > 0
      || this.synchedFilters.advancedFilters.length > 0
      || this.synchedFilters.omQuick.length > 0
      || this.synchedFilters.structuralQuick.length > 0
    );
  }

  async mounted(): Promise<void> {
    await this.applyFilters();
    await this.onRoutingDataChange();
  }

  getFilterItems(filterName: string): any[] {
    const collection = this.allFiltersOptions.find(
      (section) => section.header === filterName,
    );
    const retVal = [];

    collection.options.forEach((option) => {
      retVal.push(option);
    });

    return retVal;
  }

  async filterAsset(asset: AssetData): Promise<void> {
    if (
      this.synchedFilters.selectedAssets.length !== 0
      && !this.synchedFilters.selectedAssets.includes(asset.guid)
      && !this.synchedFilters.selectedAssets.includes(asset.assetId)
    ) {
      asset.visible = false;
      return;
    }

    const scoreCompare = this.synchedFilters.score;

    if (scoreCompare) asset.visible = scoreCompare.includes(asset.score);

    this.synchedFilters.matchFilters.forEach((m) => {
      if (m.options.length > 0) asset.visible = m.options.includes(asset[m.header]);

      if (m.value !== '') {
        if (m.type === 'number') {
          const term = parseFloat(m.value as string);

          switch (m.method) {
            case '>':
              asset.visible = parseFloat(asset[m.header]) > term;
              break;
            case '>=':
              asset.visible = parseFloat(asset[m.header]) >= term;
              break;
            case '<=':
              asset.visible = parseFloat(asset[m.header]) <= term;
              break;
            case '<':
              asset.visible = parseFloat(asset[m.header]) < term;
              break;
            case '=':
              asset.visible = parseFloat(asset[m.header]) === term;
              break;
            case '!=':
              asset.visible = parseFloat(asset[m.header]) !== term;
              break;
            default:
              break;
          }
        } else {
          if (asset[m.header] === null) return;

          const searchTerm = m.value.toString().toLowerCase();
          switch (m.method) {
            case 'Exactly':
              asset.visible = searchTerm === asset[m.header].toLowerCase();
              break;
            case 'Includes':
              asset.visible = asset[m.header]
                .toString().toLowerCase().includes(searchTerm);
              break;
            case 'Does Not Include':
              asset.visible = !asset[m.header]
                .toString().toLowerCase().includes(searchTerm);
              break;
            default:
              break;
          }
        }
      }
    });
  }

  applyFilters(): void {
    if (this.synchedFilters.selectedAssets.length > 0) {
      this.synchedSelectedMapFeatures = this.project?.assets?.filter(
        (pAsset) => this.synchedFilters?.selectedAssets?.includes(pAsset.guid)
        || this.synchedFilters?.selectedAssets?.includes(pAsset.name),
      );
    }

    // Set each asset's visible flag based on filter
    if (window.Worker) {
      const worker = new Worker('../../views/Project/webworker.js', { type: 'module' });
      this.timeRequestMade = Date.now();

      worker.postMessage({
        type: 'filterAsset',
        timeRequestMade: this.timeRequestMade,
        filters: JSON.stringify(this.synchedFilters),
        assetData: JSON.stringify(this.synchedAssetData),
      });

      worker.onmessage = (e) => {
        const data = JSON.parse(e.data);
        if (data.type != null && data.type === 'filterAsset' && this.timeRequestMade === data.timeRequestMade) {
          this.synchedAssetData = data.assetData;
          this.setMapNeedsUpdated(true);
        }
      };
    } else {
      this.synchedAssetData.forEach((asset) => this.filterAsset(asset));
      this.setMapNeedsUpdated(true);
    }
  }

  addAdvancedFilter(): void {
    this.synchedFilters.advancedFilters.push({
      key: this.synchedFilters.advancedFilters.length,
      text: this.selectedAdvancedFilter.text,
      comparison: '',
      comparisonValue: '',
      selectedOptions: [],
    });

    this.showNewAdvancedFilter = false;
  }

  removeAdvancedFilter(key: number): void {
    this.synchedFilters.advancedFilters = this.synchedFilters.advancedFilters.filter(
      (f) => f.key !== key,
    );
  }

  clearFilters(): void {
    this.$emit('clear-filters');
  }

  clearFilter(section: string, key: number): void {
    if (this.synchedFilters[section].includes('Custom')) {
      this.synchedFilters.customFromDate = '';
      this.synchedFilters.customToDate = '';
    }
    this.synchedFilters[section].splice(key, 1);
    this.synchedFilters.hiddenFilters.splice(
      this.synchedFilters.hiddenFilters.findIndex((f) => f.section === section), 1,
    );
  }

  toggleHidden(filter: FilterPair): void {
    if (this.synchedFilters.hiddenFilters.some((f) => f.value === filter.value)) {
      this.synchedFilters.hiddenFilters.splice(
        this.synchedFilters.hiddenFilters.findIndex((f) => f === filter), 1,
      );
    } else {
      this.synchedFilters.hiddenFilters.push(filter);
    }
  }

  /**
   * Sorts an array of four-character quick scores
   */
  sortQuickScore(a: { text: string, value: string }, b: { text: string, value: string }): number {
    const aVal = a.value;
    const bVal = b.value;

    for (let i = 0; i < 4; i += 1) {
      if (aVal.charAt(i) !== bVal.charAt(i)) {
        const aChar = aVal.charAt(i);
        const bChar = bVal.charAt(i);
        const aIsNumber = aChar.match('[0-9]') !== null;
        const bIsNumber = bChar.match('[0-9]') !== null;

        if (aIsNumber && bIsNumber) return parseInt(aChar, 10) - parseInt(bChar, 10);
        if (aIsNumber && !bIsNumber) return -1;
        if (!aIsNumber && bIsNumber) return 1;
        if (!aIsNumber && !bIsNumber) return aChar < bChar ? -1 : 1;
      }
    }

    return 0;
  }
}
