
































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component } from 'vue-property-decorator';
import { UserPermission } from '@/store/userpermissions/types';
import ManageUsers from './User/ManageUsers.vue';
import CustomersTable from './Customers/CustomersTable.vue';
import OrganizationsTable from './Organizations/OrganizationsTable.vue';
import NewOrganizationPopup from './Organizations/NewOrganizationPopup.vue';
import AdminPanelMixin from './AdminPanelMixin.vue';
import NewCustomerPopup from './Customers/NewCustomerPopup.vue';
import CreateUser from './User/CreateUser.vue';
import EditUsers from './User/EditUsers.vue';

@Component({
  components: {
    ManageUsers,
    CustomersTable,
    OrganizationsTable,
    NewOrganizationPopup,
    NewCustomerPopup,
    CreateUser,
    EditUsers,
  },
})
export default class AdminPanel extends AdminPanelMixin {
  adminPanelSearch = '';

  activeTab = 0;

  tabOptions = [
    {
      name: 'Organizations',
      value: 'organizations',
      permission: this.hasPermissionAdminCanSeeOrganizationTab,
    },
    {
      name: 'Customers',
      value: 'customers',
      permission: this.hasPermissionAdminCanSeeCustomerTab,
    },
    {
      name: 'Users',
      value: 'users',
      permission: this.hasPermissionAdminCanSeeUserTab,
    },
  ];

  userData: Array<{
    role: any,
    auth0Id: any,
    detailedUserData: any,
    detailedUser: any,
    isExpanded: boolean
    visible: boolean
    resetDialog: boolean,
    guid?: string,
    fullName?: string
  }> = [];

  selectedItems = [];

  get userDataFiltered(): Array<any> {
    if (!this.userData) {
      return undefined;
    }
    // Don't pass these filters to the table as there are other components which use this data.
    return this.userData
      .filter((value) => value.visible)
      .map((value) => {
        // eslint-disable-next-line no-param-reassign
        value.guid = value.detailedUserData.guid; // Add guid for integrityTable
        // eslint-disable-next-line no-param-reassign
        value.fullName = `${value.detailedUserData.firstname} ${value.detailedUserData.lastname}`;
        return value;
      });
  }

  /**
   * @description Get the value from the current tab.
   * @returns {string} 'value' from the current tab
   */
  get activeTabValue(): string {
    return this.availableTabOptions[this.activeTab].value;
  }

  /**
   * @description Get all tabs that the given user has permission to.
   * @returns all tabs user has permission to
   */
  get availableTabOptions(): any {
    return this.tabOptions.filter((tab) => tab.permission());
  }

  async mounted(): Promise<void> {
    await this.fetchCustomerData();
    await this.fetchAllDetailedUserData();
    await this.fetchInactiveDetailedUserData();
    this.fetchOrganizationData();
    this.fetchCustomerData();
  }

  /**
   * @description Emit 'clearUserTableFilters' for all tabs to clear their filters.
   */
  clearFilters(): void {
    this.$root.$emit('clearUserTableFilters');
  }

  /**
   * @returns true if the user has the permission ADMIN_ORGANIZATION_SECTION_SHOWORGANIZATIONSECTION
   */
  hasPermissionAdminCanSeeOrganizationTab(): boolean {
    return this.hasPermission(UserPermission.ADMIN_ORGANIZATION_SECTION_SHOWORGANIZATIONSECTION);
  }

  /**
   * @returns true if the user has the permission ADMIN_ORGANIZATION_SECTION_CREATEANORGANIZATION
   */
  hasPermissionAdminCreateAnOrganization(): boolean {
    return this.hasPermission(UserPermission.ADMIN_ORGANIZATION_SECTION_CREATEANORGANIZATION);
  }

  /**
   * @returns true if the user has the permission ADMIN_ORGANIZATION_SECTION_EDITANORGANIZATION
   */
  hasPermissionAdminEditAnOrganization(): boolean {
    return this.hasPermission(UserPermission.ADMIN_ORGANIZATION_SECTION_EDITANORGANIZATION);
  }

  /**
   * @returns true if the user has the permission ADMIN_ORGANIZATION_SECTION_DELETEANORGANIZATION
   */
  hasPermissionAdminDeleteAnOrganization(): boolean {
    return this.hasPermission(UserPermission.ADMIN_ORGANIZATION_SECTION_DELETEANORGANIZATION);
  }

  /**
   * @returns true if the user has the permission ADMIN_CUSTOMER_SECTION_SHOWCUSTOMERSECTION
   */
  hasPermissionAdminCanSeeCustomerTab(): boolean {
    return this.hasPermission(UserPermission.ADMIN_CUSTOMER_SECTION_SHOWCUSTOMERSECTION);
  }

  /**
   * @returns true if the user has the permission ADMIN_USER_SECTION_SHOW_USER_SECTION
   */
  hasPermissionAdminCanSeeUserTab(): boolean {
    return this.hasPermission(UserPermission.ADMIN_USER_SECTION_SHOW_USER_SECTION);
  }

  /**
   * @returns true if the user has the permission ADMIN_CUSTOMER_SECTION_CREATEACUSTOMER
   */
  hasPermissionAdminCreateACustomer(): boolean {
    return this.hasPermission(UserPermission.ADMIN_CUSTOMER_SECTION_CREATEACUSTOMER);
  }

  /**
   * @returns true if the user has the permission ADMIN_CUSTOMER_SECTION_EDITACUSTOMER
   */
  hasPermissionAdminEditACustomer(): boolean {
    return this.hasPermission(UserPermission.ADMIN_CUSTOMER_SECTION_EDITACUSTOMER);
  }

  /**
   * @returns true if the user has the permission ADMIN_CUSTOMER_SECTION_DELETEACUSTOMER
   */
  hasPermissionAdminDeleteACustomer(): boolean {
    return this.hasPermission(UserPermission.ADMIN_CUSTOMER_SECTION_DELETEACUSTOMER);
  }

  /**
   * @returns true if the user has the permission ADMIN_USER_SECTION_EDIT_MULTI_ADD_MANAGED_USERS
   * or ADMIN_USER_SECTION_EDIT_MULTI_ADD_PROJECT
   */
  hasPermissionAdminEditUser(): boolean {
    return this.hasPermission(UserPermission.ADMIN_USER_SECTION_EDIT_MULTI_ADD_MANAGED_USERS)
      || this.hasPermission(UserPermission.ADMIN_USER_SECTION_EDIT_MULTI_ADD_PROJECT);
  }

  /**
   * @returns true if the user has the permission ADMIN_USER_SECTION_ADDNEWUSER
   */
  hasPermissionAdminAddUser(): boolean {
    return this.hasPermission(UserPermission.ADMIN_USER_SECTION_ADDNEWUSER);
  }
}
