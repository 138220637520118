





























































































































































































import { ProjectActions } from '@/store/project/actions';
import { ProjectHeaders } from '@/store/project/types';
import { RoutingActions } from '@/store/routing/actions';
import { DeleteRoutingDataDTO, RoutingData, RoutingDTONode } from '@/store/routing/types';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import IntegrityTable, {
  AdditionalFilterFunction, FillFunctionContainer, FilterFunction, processDate,
} from '@/components/IntegrityTable/IntegrityTable.vue';
import {
  NODEITEM_LINESEGMENT_GUID,
  NODEITEM_MANHOLE_GUID,
} from '@/common/Constants';
import { RoutingMutations } from '@/store/routing/mutations';
import { UserPermission } from '@/store/userpermissions/types';
import { uuid } from 'vue-uuid';
import IntegrityDelete from '../IntegrityDelete/IntegrityDelete.vue';
import RouteOptions from '../RouteOptions/RouteOptions.vue';
import getRouteColor from './RoutingUtils';
import UserPermissionsMixin from '../UserPermissions/UserPermissionsMixin.vue';

const projectModule = namespace('project');
const routingModule = namespace('routing');

@Component({
  components: {
    IntegrityTable,
    IntegrityDelete,
    RouteOptions,
  },
})
export default class Routing extends UserPermissionsMixin {
  @routingModule.Action(RoutingActions.FETCH_ROUTING_DATA) fetchRoutingData;

  @routingModule.Mutation(RoutingMutations.SET_ROUTING_DATA) setRoutingData;

  @routingModule.State('routingData') routingData: RoutingData[] | undefined;

  @routingModule.State('routeAssets') routingAssets: RoutingDTONode[];

  @routingModule.State('routingLoading') routingLoading: boolean | undefined;

  @routingModule.Action(RoutingActions.FETCH_ROUTE_ASSETS) fetchRouteAssets;

  @routingModule.Action(RoutingActions.DELETE_ROUTING) deleteRoutingGuids;

  @routingModule.State('deleteRoutingData') deleteRoutingData: string[] | undefined;

  @routingModule.State('deleteRoutingLoading') deleteRoutingLoading: boolean;

  @routingModule.State('deleteRoutingError') deleteRoutingError: string | undefined;

  @projectModule.Action(ProjectActions.FETCH_HEADER_DATA) fetchProjectHeaders;

  @projectModule.State('headers') projectHeaders: ProjectHeaders | undefined;

  deleteLoading = false;

  isDeleteError = false;

  snack = false;

  snackBarMessage = '';

  snackColor = 'black';

  @Prop() readonly id!: Vue;

  selectedItems = [];

  routeAssetType = '';

  isRouteActive = false;

  routeAssets = [];

  editColor = '';

  editName = '';

  editDescription = '';

  routeGuid = '';

  async mounted(): Promise<void> {
    if (!this.hasPermissionRoutingCommon) {
      this.goToErrorPage();
    }
    await this.fetchProjectHeaders([this.id]);
    await this.fetchRoutingData(this.id);
  }

  tabOptions = [
    {
      name: 'Manhole',
      value: 'Manhole',
      guid: NODEITEM_MANHOLE_GUID,
    },
    {
      name: 'Line Segment',
      value: 'Line Segment',
      guid: NODEITEM_LINESEGMENT_GUID,
    },
  ];

  activeTab = 0;

  search = '';

  deleteWorkOrders = false;

  allHeaders = [
    {
      text: 'Route Name',
      value: 'routeName',
      align: 'start',
      filterable: true,
      class: 'sticky',
      cellClass: 'sticky',
    },
    {
      text: 'Color',
      value: 'color',
      align: 'center',
      filterable: true,
      class: 'sticky',
      cellClass: 'sticky',
    },
    {
      text: 'Task Types',
      value: 'taskType',
      align: 'start',
      filterable: true,
    },
    {
      text: 'Next Due Date',
      value: 'nextDueDate',
      align: 'start',
      filterable: true,
    },
    {
      text: 'Created By',
      value: 'createdBy',
      align: 'start',
      filterable: true,
    },
    {
      text: 'Created Date',
      value: 'createdDate',
      align: 'start',
      filterable: true,
    },
    {
      text: 'Description',
      value: 'description',
      align: 'start',
      filterable: true,
    },
    {
      text: 'Edit',
      value: 'edit',
      align: 'center',
      sortable: false,
    },
    {
      text: 'Delete',
      value: 'delete',
      align: 'center',
      sortable: false,
    },
  ];

  matchFilters = [
    {
      header: 'routeName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'color',
      type: 'color',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'taskType',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'nextDueDate',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'createdBy',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'createdDate',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'description',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get headers(): any[] {
    let list = this.allHeaders;
    if (!this.hasPermissionRoutingDelete) {
      list = list.filter((item) => item.value !== 'delete');
    }
    if (!this.hasPermissionRoutingEdit) {
      list = list.filter((item) => item.value !== 'edit');
    }
    return list;
  }

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'createdDate',
          functionVariables: [processDate.toString()],
          fillFunction: function fillCreatedDate(
            item: RoutingData,
            processDateFunctionString: string,
          ): string {
          // eslint-disable-next-line no-new-func
            return new Function(`return ${processDateFunctionString};`)()(item.createdDate);
          },
        },
        {
          headerValue: 'nextDueDate',
          functionVariables: [processDate.toString()],
          fillFunction: function fillNextDueDate(
            item: RoutingData,
            processDateFunctionString: string,
          ): string {
          // eslint-disable-next-line no-new-func
            return new Function(`return ${processDateFunctionString};`)()(item.nextDueDate);
          },
        },
        {
          headerValue: 'taskType',
          functionVariables: [],
          fillFunction: function fillTaskType(
            item: RoutingData,
          ): string {
            if (item.taskType != null) {
              return item.taskType.join(', ');
            }
            return '';
          },
        },
      ],
    };
    return returnValue;
  }

  get additionalFilterFunctions(): AdditionalFilterFunction {
    const filterFunctions: FilterFunction[] = [
      {
        functionVariables: [this.tabOptions, this.activeTab],
        filterFunction: function isCorrectTab(item, tabOptions, activeTab) {
          return item.categoryItemGuid === tabOptions[activeTab].guid;
        },
      },
    ];
    return {
      updateKey: uuid.v4(),
      filterFunctions,
    };
  }

  get routesNotDeletedString(): string {
    const routeNames = this.deleteRoutingData?.map(
      (value) => this.routingData?.find((data) => data.guid === value)?.routeName,
    );
    return `Routes not deleted: ${routeNames?.join(', ')}`;
  }

  get filteredRoutingData(): RoutingData[] {
    if (!this.routingData) {
      return undefined;
    }

    return this.routingData;
  }

  /**
   * @returns true if the user has the permission ROUTING_COMMON
   */
  get hasPermissionRoutingCommon(): boolean {
    return this.hasPermission(UserPermission.ROUTING_COMMON);
  }

  /**
   * @returns true if the user has the permission ROUTING_EDIT_A_ROUTE
   */
  get hasPermissionRoutingEdit(): boolean {
    return this.hasPermission(UserPermission.ROUTING_EDIT_A_ROUTE);
  }

  /**
   * @returns true if the user has the permission ROUTING_DELETE_A_ROUTE
   */
  get hasPermissionRoutingDelete(): boolean {
    return this.hasPermission(UserPermission.ROUTING_DELETE_A_ROUTE);
  }

  async handleEdit(guid: string): Promise<void> {
    const index = this.routingData.findIndex((r) => r.guid === guid);
    if (this.routingData[index].nodes == null || this.routingData[index].nodes.length === 0) {
      await this.fetchRouteAssets(guid);
      this.routingData[index].nodes = [...this.routingAssets];
    }

    const selectedRoute = this.routingData.find((r) => r.guid === guid);

    this.editName = selectedRoute.routeName;
    this.editDescription = selectedRoute.description;
    this.editColor = this.getRouteColor(selectedRoute.color);
    this.routeGuid = selectedRoute.guid;
    this.routeAssets = [...selectedRoute.nodes];

    this.routeAssetType = this.tabOptions[this.activeTab].value;
    this.isRouteActive = true;
  }

  toggleRoute(dialog: boolean): void{
    this.isRouteActive = dialog;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeAsset(asset: { guid: any; }): void {
    const index = this.routeAssets.findIndex((sa) => sa.guid === asset.guid);
    this.routeAssets.splice(index, 1);
  }

  async removeSelectedRoutes(): Promise<void> {
    await this.deleteRoutes(this.selectedItems);
  }

  async deleteRoutes(routes: RoutingData[]): Promise<void> {
    const deleteDTO: DeleteRoutingDataDTO = {
      routeGuids: routes.map((value) => value.guid),
      deleteRouteWorkOrders: this.deleteWorkOrders,
    };
    try {
      await this.deleteRoutingGuids(deleteDTO);
      await this.setRoutingData(this.routingData.filter((value) => !routes.includes(value)));
      this.updateSnackBar(`Selected Routes
        ${this.deleteWorkOrders ? 'and Work Orders ' : ''}
      Deleted`, 'green');
    } catch (e) {
      this.isDeleteError = true;
      if (this.routingData?.length > 0) {
        await this.setRoutingData(this.routingData.filter((value) => !routes.includes(value)));
      }
    } finally {
      this.reset();
    }
  }

  reset(): void {
    this.selectedItems = [];
    this.deleteWorkOrders = false;
  }

  updateSnackBar(message: string, color: string): void {
    this.snackBarMessage = message;
    this.snackColor = color;
    this.snack = true;
  }

  getRouteColor(color: string): string | undefined {
    return getRouteColor(color);
  }

  postResult(): void {
    this.fetchRoutingData(this.id);
  }
}
