













































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import IntegrityTable, { FillFunctionContainer, processDateWithTableObject } from '@/components/IntegrityTable/IntegrityTable.vue';
import { namespace } from 'vuex-class';
import { ScopeHistoryMutations } from '@/store/scopeHistory/mutations';
import { ScopeHistoryActions } from '@/store/scopeHistory/actions';
import { uuid } from 'vue-uuid';

const scopeHistoryModule = namespace('scopeHistory');

@Component({
  components: {
    IntegrityTable,
  },
})
export default class HistoryDropDown extends Vue {
  /* eslint-disable @typescript-eslint/no-explicit-any,
    @typescript-eslint/explicit-module-boundary-types */
  @Prop({ default: [] }) readonly data: any[];

  @Prop({ default: false }) readonly isEditMode: boolean

  @Prop({ default: false }) readonly loading: boolean;

  @Prop({ default: ['d1edc644-292c-4fcb-b333-73474f95467a'] }) readonly projectGuids: string[];

  @scopeHistoryModule.Mutation(ScopeHistoryMutations.SET_ASSET_WORKORDER_HISTORY_DATA)
    setAssetWorkOrderHistoryData;

  @scopeHistoryModule.Action(
    ScopeHistoryActions.PATCH_ASSET_WORKORDER_HISTORY_DATA,
  ) patchAssetWorkOrderHistoryData;

  @scopeHistoryModule.State('assetPatchWorkOrderLoading') assetPatchWorkOrderLoading: boolean;

  @scopeHistoryModule.State('assetPatchWorkOrderError') assetPatchWorkOrderError: string | undefined;

  tableData: any[] = [];

  tableHeaders = [
    {
      text: 'Deployment', value: 'deployment', sortable: true, filterable: false,
    },
    {
      text: '', value: 'hasCustomerDeliverables', sortable: false, filterable: false,
    },
    {
      text: 'Direction', value: 'direction', sortable: true, filterable: true,
    },
    {
      text: 'WO Type', value: 'woType', sortable: true, filterable: true,
    },
    {
      text: 'WO Status', value: 'woStatus', sortable: true, filterable: true,
    },
    {
      text: 'Collection Results', value: 'collectionResults', sortable: true, filterable: true,
    },
    {
      text: 'Notes', value: 'notes', sortable: true, filterable: true,
    },
    {
      text: 'Length Surveyed', value: 'length', sortable: true, filterable: true,
    },
    {
      text: 'Media?', value: 'media', sortable: true, filterable: true,
    },
    {
      text: 'Date Performed', value: 'date', sortable: true, filterable: true,
    },
    {
      text: 'Surveyor', value: 'surveyor', sortable: true, filterable: true,
    },
    {
      text: 'Active',
      value: 'active',
      editable: true,
      options: [],
      sortable: true,
      filterable: true,
    },
  ];

  matchFilters = [
    {
      header: 'deployment', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'hasCustomerDeliverables', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'direction', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'woType', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'woStatus', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'collectionResults', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'notes', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'length', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'media', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'date', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'surveyor', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'active', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
  ]

  sortBy = 'date';

  blankMatchFilters = {}

  showSnackbar = false as boolean;

  snackbarColor = '' as string;

  snackbarMessage = '' as string;

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'media',
          functionVariables: [],
          fillFunction: function convertMedia(
            item: any,
          ): string {
            return item.media ? 'Yes' : 'No';
          },
        },
        {
          headerValue: 'active',
          functionVariables: [],
          fillFunction: function convertActive(
            item: any,
          ): string {
            return item.active ? 'Yes' : 'No';
          },
        },
        {
          headerValue: 'date',
          functionVariables: ['date'],
          fillFunction: processDateWithTableObject,
        },
      ],
    };
    return returnValue;
  }

  mounted(): void {
    this.onDataChange();
  }

  @Watch('data', { deep: true })
  onDataChange(): void {
    this.tableData = this.data != null ? this.data : undefined;
  }

  clearAllFilters(): void {
    (this.$refs.collectionSubTable as IntegrityTable).clearAllMatchFilters();
  }

  /**
     * @description Gets the params for the deployment page
    */
  getDeploymentPageParams(): any {
    return { ids: JSON.stringify(this.projectGuids) };
  }

  /**
     * Gets the deployment guid query filter from the requested deployment/workorder item
     */
  getDeploymentsPageQuery(item: any): any {
    const returnObject = {
      deploymentGuids: [],
    };
    if (item?.deploymentGuid != null) {
      returnObject.deploymentGuids.push(item.deploymentGuid);
    }
    return returnObject;
  }

  /**
     * @description Called when the deployment is edited
     */
  async deploymentEdited(deployment: any): Promise<void> {
    if (deployment?.deploymentGuid == null) {
      // Reset the data since we don't have a guid we're trying to edit
      this.onDataChange();
    } else {
      let active = false;
      if (deployment?.active != null && deployment.active.toLowerCase() === 'yes') {
        active = true;
      }
      await this.patchAssetWorkOrderHistoryData({
        guid: deployment.deploymentGuid,
        dto: {
          active,
        },
      });
      if (this.assetPatchWorkOrderError == null) {
        const newData = [...this.data];
        const editedData = newData.find(
          (dep) => dep.deploymentGuid === deployment.deploymentGuid,
        );
        if (editedData != null) {
          editedData.active = active;
          this.setAssetWorkOrderHistoryData(newData);
          this.snackbarColor = 'green';
          this.snackbarMessage = 'Deployment updated successfully';
          this.showSnackbar = true;
        } else {
          this.snackbarColor = 'red';
          this.snackbarMessage = 'An error occured while updating the deployment';
          this.showSnackbar = true;
        }
      } else {
        this.snackbarColor = 'red';
        this.snackbarMessage = this.assetPatchWorkOrderError;
        this.showSnackbar = true;
      }
    }
  }

  handleInfo(): void {
    console.error('NOT IMPLEMENTED');
  }
}
