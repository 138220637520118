





























































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-curly-newline */
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import IntegrityTable, { AdditionalFilterFunction, FillFunctionContainer, FilterFunction, processDateWithTableObject } from '@/components/IntegrityTable/IntegrityTable.vue';
import { ScopeHistoryActions } from '@/store/scopeHistory/actions';
import { AssetCountsMetrics } from '@/store/metrics/types';
import { MetricsActions } from '@/store/metrics/actions';
import utils from '@/components/Report/util';
import { ScopeHistoryMutations } from '@/store/scopeHistory/mutations';
import { ReleasesActions } from '@/store/releases/actions';
import { ReleaseTableData, Release, ReleaseOverviewObject } from '@/store/releases/types';

import { UserPermission } from '@/store/userpermissions/types';
import { uuid } from 'vue-uuid';
import {
  HistoryEntry, AssetHistory, AssetWorkOrderHistory,
} from './types';
import { TableMode } from '../ExportDataPopout/types';
import HistoryDropDown from './HistoryDropDown.vue';
import { DataTableHeader } from '../AssetTable/types';
import NewAssignmentDialog from '../ScopeNavigation/NewAssignmentDialog/NewAssignmentDialog.vue';
import ReleaseOverview from '../ReleaseOverview/ReleaseOverview.vue';
import CustomerPrepDialog from './CustomerPrep/CustomerPrepDialog.vue';
import ReleaseTable from '../ReleaseTable/ReleaseTable.vue';
import { ReleaseAssetType } from '../ReleaseTable/types';
import CustomerPrep from '../CustomerPrep/CustomerPrep.vue';
import UserPermissionsMixin from '../UserPermissions/UserPermissionsMixin.vue';

const scopeHistoryModule = namespace('scopeHistory');
const userPrefsModule = namespace('userPrefs');
const metricsModule = namespace('metrics');
const releaseModule = namespace('releases');

@Component({
  components: {
    IntegrityTable,
    HistoryDropDown,
    NewAssignmentDialog,
    ReleaseOverview,
    CustomerPrepDialog,
    ReleaseTable,
    CustomerPrep,
  },
})
export default class ScopeHistory extends UserPermissionsMixin {
  @scopeHistoryModule.State('data') scopeHistory: HistoryEntry[];

  @scopeHistoryModule.State('assetHistoryPatchMessage') assetHistoryPatchMessage: string;

  @scopeHistoryModule.State('assetHistory') assetHistory: AssetHistory[];

  @scopeHistoryModule.State('loading') loading: boolean;

  @scopeHistoryModule.State('assetWorkOrderHistory') assetWorkOrderHistory: AssetWorkOrderHistory[] | undefined;

  @scopeHistoryModule.State('assetWorkOrderLoading') assetWorkOrderLoading: boolean;

  @scopeHistoryModule.State('assetWorkOrderError') assetWorkOrderError: string | undefined;

  @scopeHistoryModule.State('assetHistoryPatchError') assetHistoryPatchError: boolean;

  @scopeHistoryModule.Action(ScopeHistoryActions.PATCH_HISTORY_DATA) patchHistoryData;

  @scopeHistoryModule.Action(ScopeHistoryActions.FETCH_ASSET_HISTORY_DATA) fetchAssetHistoryData;

  @scopeHistoryModule.Action(
    ScopeHistoryActions.FETCH_ASSET_WORKORDER_HISTORY_DATA,
  ) fetchAssetWorkOrderHistoryData;

  @scopeHistoryModule.Mutation(ScopeHistoryMutations.SET_ASSET_HISTORY_DATA) setAssetHistoryData;

  @userPrefsModule.State('displayImperial') displayImperial: boolean;

  @metricsModule.Action(MetricsActions.FETCH_ASSET_COUNTS_METRICS) fetchAssetCountsMetrics: any;

  @metricsModule.State('assetCountsMetrics') assetCountsMetrics: AssetCountsMetrics;

  @releaseModule.Action(ReleasesActions.FETCH_RELEASE_TABLE_DATA) fetchReleaseTableData: any;

  @releaseModule.State('releaseTableData') releaseTableData: ReleaseTableData[];

  @releaseModule.State('releaseTableDataLoading') releaseTableDataLoading: boolean;

  @releaseModule.Action(ReleasesActions.GET_INSPECTION_RELEASES) getInspectionReleases;

  @releaseModule.State('releaseOverview') releaseOverview: ReleaseOverviewObject[];

  @Prop() readonly projectGuids: string;

  @Prop() readonly data: HistoryEntry[];

  @Watch('assetHistoryPatchMessage')
  onAssetHistoryPatchMessageChange(): void {
    if (this.assetHistoryPatchMessage && this.assetHistoryPatchMessage !== '') {
      this.showSnackbar = true;
    }
  }

  @Watch('expanded', { deep: true })
  onExpandedChange(): void {
    if (this.expanded.length > 0) {
      this.fetchAssetWorkOrderHistoryData(this.expanded[0]?.guid);
    }
  }

  @Watch('releaseOverview')
  onReleaseOverviewChange(): void {
    const releaseData = this.releaseOverview.map((r) => (
      {
        releaseName: r.releaseName,
        releaseGuid: r.releaseGuid,
      }));
    this.setReleases(releaseData);
  }

  tableHeight: string | number = '100%';

  projectGuidList: string[] = [];

  manholeHeaders = [
    { text: 'Asset ID', value: 'name', sortable: true, filterable: true },
    {
      text: 'Collection Status',
      value: 'status',
      editable: true,
      sortable: true,
      filterable: true,
      options: [
        { name: 'Unassigned' },
        { name: 'Assigned' },
        { name: 'Complete' },
        { name: 'Follow-Up Required' },
      ],
    },
    { text: 'Date Collected', value: 'date', sortable: true, filterable: true },
  ];

  lineSegmentHeaders = [
    { text: 'Asset ID', value: 'name', sortable: true, filterable: true },
    {
      text: 'Collection Status',
      value: 'status',
      editable: true,
      sortable: true,
      filterable: true,
      options: [
        { name: 'Unassigned' },
        { name: 'Assigned' },
        { name: 'Complete' },
        { name: 'Follow-Up Required' },
      ],
    },
    { text: 'US MH', value: 'usmh', sortable: true, filterable: true },
    { text: 'DS MH', value: 'dsmh', sortable: true, filterable: true },
    { text: 'Length Surveyed', value: 'length', sortable: true, filterable: true },
    { text: 'Expected Length', value: 'gisLength', sortable: true, filterable: true },
    { text: 'Date Collected', value: 'date', sortable: true, filterable: true },
  ];

  matchFiltersManhole = [
    { header: 'name', type: 'string', value: '', method: '', options: [], tempValue: '' },
    { header: 'status', type: 'string', value: '', method: '', options: [], tempValue: '' },
    { header: 'date', type: 'string', value: '', method: '', options: [], tempValue: '' },
  ]

  matchFiltersPipe = [
    { header: 'name', type: 'string', value: '', method: '', options: [], tempValue: '' },
    { header: 'status', type: 'string', value: '', method: '', options: [], tempValue: '' },
    { header: 'usmh', type: 'string', value: '', method: '', options: [], tempValue: '' },
    { header: 'usmh', type: 'string', value: '', method: '', options: [], tempValue: '' },
    { header: 'dsmh', type: 'string', value: '', method: '', options: [], tempValue: '' },
    { header: 'length', type: 'string', value: '', method: '', options: [], tempValue: '' },
    { header: 'gisLength', type: 'string', value: '', method: '', options: [], tempValue: '' },
    { header: 'date', type: 'string', value: '', method: '', options: [], tempValue: '' },
  ]

  sortBy = 'name';

  blankMatchFilters = {}

  tabOptions = [
    { name: 'Manhole', value: 'Manhole' },
    { name: 'Line Segment', value: 'Line Segment' },
  ];

  activeTab = 0;

  search = '';

  tableMode = TableMode.NORMAL;

  expanded = [];

  // The data coming back from the history table on what is selected
  tableModel = [];

  showNewAssignDialog = false;

  beforeEditCache = []

  editedAssets: AssetHistory[] = [];

  showEditWarning = false;

  showSnackbar = false;

  selectedSection = 'Collection';

  releaseOptions: Release[] = [];

  selectedRelease: Release = undefined;

  releaseOpen = false;

  // The data coming back from the customer prep table on what is selected
  customerPrepTableModel = [];

  isCustomerPrepReleaseDialogOpen = false;

  get sectionOptions(): string[] {
    const retVal = [];

    if (this.hasPermissionAssetHistoryCollectionSectionViewTable) {
      retVal.push('Collection');
    }

    if (this.hasPermissionAssetHistoryCustomerPrepSectionViewTable) {
      retVal.push('Customer Prep');
    }

    if (this.hasPermissionAssetHistoryReleasesSectionViewallReleases) {
      retVal.push('Releases');
    }

    return retVal;
  }

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'date',
          functionVariables: ['date'],
          fillFunction: processDateWithTableObject,
        },
      ],
    };
    return returnValue;
  }

  get additionalFilterFunctions(): AdditionalFilterFunction {
    const filterFunctions: FilterFunction[] = [
      {
        functionVariables: [['Manhole', 'Line Segment'], this.activeTab],
        filterFunction: function isCorrectTab(item, assetTypes, activeTab) {
          return item.type === assetTypes[activeTab];
        },
      },
    ];
    return {
      updateKey: uuid.v4(),
      filterFunctions,
    };
  }

  get filteredTableData(): AssetHistory[] {
    if (!this.assetHistory) {
      return undefined;
    }
    return this.assetHistory;
  }

  get tableHeaders(): DataTableHeader[] {
    return this.activeTab === 0 ? this.manholeHeaders : this.lineSegmentHeaders;
  }

  get matchFilters(): any {
    return this.activeTab === 0 ? this.matchFiltersManhole : this.matchFiltersPipe;
  }

  get selectedDeployments(): unknown[] {
    // see if we can safely grab deployments array
    if (this.expanded != null && this.expanded.length > 0) {
      const returnObject = this.assetWorkOrderHistory != null
        ? [...this.assetWorkOrderHistory] : [];
      return returnObject;
    }
    return [];
  }

  get assetTotal(): string {
    if (this.activeTab === 0) return utils.numberWithCommas(this.manholesTotal);
    return utils.numberWithCommas(
      utils.getDisplayDistanceFtM(this.displayImperial, this.lineSegmentsTotal),
    );
  }

  get assetAddressed(): string {
    if (this.activeTab === 0) return utils.numberWithCommas(this.manholesAddressed);
    return utils.numberWithCommas(
      utils.getDisplayDistanceFtM(this.displayImperial, this.lineSegmentsAddressed),
    );
  }

  get assetCollected(): string {
    if (this.activeTab === 0) return utils.numberWithCommas(this.manholesCollected);
    return utils.numberWithCommas(
      utils.getDisplayDistanceFtM(this.displayImperial, this.lineSegmentsCollected),
    );
  }

  get manholesTotal(): number {
    return this.assetCountsMetrics?.manholes?.total != null
      ? this.assetCountsMetrics.manholes.total : 0;
  }

  get manholesAddressed(): number {
    return this.assetCountsMetrics?.manholes?.addressed != null
      ? this.assetCountsMetrics.manholes.addressed : 0;
  }

  get manholesCollected(): number {
    return this.assetCountsMetrics?.manholes?.collected != null
      ? this.assetCountsMetrics.manholes.collected : 0;
  }

  get lineSegmentsTotal(): number {
    return this.assetCountsMetrics?.lineSegments?.total != null
      ? this.assetCountsMetrics.lineSegments.total : 0;
  }

  get lineSegmentsAddressed(): number {
    return this.assetCountsMetrics?.lineSegments?.addressed != null
      ? this.assetCountsMetrics.lineSegments.addressed : 0;
  }

  get lineSegmentsCollected(): number {
    return this.assetCountsMetrics?.lineSegments?.collected != null
      ? this.assetCountsMetrics.lineSegments.collected : 0;
  }

  get unitLabel(): string {
    if (this.activeTab === 0) return '';
    return this.displayImperial ? 'ft' : 'm';
  }

  /**
   * @description Gets the selected asset data
   */
  get selectedAssets(): AssetHistory[] {
    return this.tableModel;
  }

  /**
   * @description Gets the string value of the active tab
   */
  get activeTabString(): string {
    return this.tabOptions[this.activeTab].value;
  }

  get isManholeTab(): boolean {
    return this.activeTabString === 'Manhole';
  }

  get unsavedChanges(): boolean {
    return this.editedAssets.length > 0;
  }

  /**
   * @returns true if the user has the permission ASSET_HISTORY_COLLECTION_SECTION_VIEW_TABLE
   */
  get hasPermissionAssetHistoryCollectionSectionViewTable(): boolean {
    return this.hasPermission(UserPermission.ASSET_HISTORY_COLLECTION_SECTION_VIEW_TABLE);
  }

  /**
   * @returns true if the user has the permission ASSET_HISTORY_CUSTOMER_PREP_SECTION_VIEW_TABLE
   */
  get hasPermissionAssetHistoryCustomerPrepSectionViewTable(): boolean {
    return this.hasPermission(UserPermission.ASSET_HISTORY_CUSTOMER_PREP_SECTION_VIEW_TABLE);
  }

  /**
   * @returns true if the user has the permission ASSET_HISTORY_RELEASES_SECTION_VIEW_ALL_RELEASES
   */
  get hasPermissionAssetHistoryReleasesSectionViewallReleases(): boolean {
    return this.hasPermission(UserPermission.ASSET_HISTORY_RELEASES_SECTION_VIEW_ALL_RELEASES);
  }

  /**
   * @returns true if the user has the permission
   * ASSET_HISTORY_COLLECTION_SECTION_EDIT_COLLECTION_STATUS
   */
  get hasPermissionAssetHistoryCollectionSectionEditCollectionStatus(): boolean {
    return this.hasPermission(
      UserPermission.ASSET_HISTORY_COLLECTION_SECTION_EDIT_COLLECTION_STATUS,
    );
  }

  /**
   * @returns true if the user has the permission ASSET_HISTORY_RELEASES_SECTION_EDIT_A_RELEASE
   */
  get hasPermissionAssetHistoryReleasesSectionEditaRelease(): boolean {
    return this.hasPermission(UserPermission.ASSET_HISTORY_RELEASES_SECTION_EDIT_A_RELEASE);
  }

  /**
   * @returns true if the user has the permission ASSET_HISTORY_RELEASES_SECTION_DELETE_A_RELEASE
   */
  get hasPermissionAssetHistoryReleasesSectionDeleteaRelease(): boolean {
    return this.hasPermission(UserPermission.ASSET_HISTORY_RELEASES_SECTION_DELETE_A_RELEASE);
  }

  /**
   * @returns true if the user has the permission
   * ASSET_HISTORY_RELEASES_SECTION_REMOVE_AN_ASSET_FROM_A_RELEASE
   */
  get hasPermissionAssetHistoryReleasesSectionRemoveanAssetfromaRelease(): boolean {
    return this.hasPermission(
      UserPermission.ASSET_HISTORY_RELEASES_SECTION_REMOVE_AN_ASSET_FROM_A_RELEASE,
    );
  }

  clearAllFilters(): void {
    (this.$refs.collectionTable as IntegrityTable).clearAllMatchFilters();
    (this.$refs.historyDropdown as HistoryDropDown).clearAllFilters();
  }

  get custPrepHeaders(): DataTableHeader[] {
    const headers = [
      {
        text: 'Asset ID',
        value: 'name',
        type: 'string',
        sortable: true,
        filterable: true,
        class: 'sticky',
        cellClass: 'sticky',
      },
      {
        text: '',
        value: 'hasCustomerDeliverables',
        type: 'boolean',
        sortable: false,
        filterable: false,
        class: 'sticky',
        cellClass: 'sticky',
      },
      {
        text: 'Reporting Status',
        value: 'reportingStatus',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Score',
        value: 'score',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Release',
        value: 'release',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Date Inspected',
        value: 'date',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Date Released',
        value: 'dateReleased',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Date Shipped',
        value: 'dateShipped',
        type: 'string',
        sortable: true,
        filterable: true,
      },
    ];

    const lsHeaders = [
      {
        text: 'US MH',
        value: 'usmh',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'DS MH',
        value: 'dsmh',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Direction',
        value: 'direction',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Length Surveyed',
        value: 'length',
        type: 'string',
        sortable: true,
        filterable: true,
      },
    ];

    if (this.activeTab === 1) headers.splice(4, 0, ...lsHeaders);

    return headers;
  }

  get custMatchFilters(): any {
    const matchFilters = this.custPrepHeaders.map((header) => ({
      header: header.value,
      type: header.type,
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    }));

    return matchFilters;
  }

  get isCustomerPrepReleaseButtonDisabled(): boolean {
    return this.customerPrepTableModel == null
      || this.customerPrepTableModel.length === 0;
  }

  releaseAssetType(selectedRelease: Release): ReleaseAssetType {
    if (this.filteredReleaseTableData() != null && this.filteredReleaseTableData().length > 0) {
      if (this.filteredReleaseTableData()[0].assetType === 'Line Segment') {
        return ReleaseAssetType.PIPE;
      }
    }
    return ReleaseAssetType.MANHOLE;
  }

  get releaseTableTabStrings(): string[] {
    const returnArray = Array.from(
      new Set(this.releaseTableData.map((value) => value.releaseName)),
    );
    if (returnArray != null && returnArray.length === 0) {
      return ['No Release Table Data'];
    }
    return returnArray;
  }

  filteredReleaseTableData(): ReleaseTableData[] {
    if (this.selectedRelease == null) {
      return [];
    }
    const returnValue = this.releaseTableData.filter(
      (value) => value.releaseGuid === this.selectedRelease.releaseGuid,
    );

    return returnValue;
  }

  @Watch('activeTab')
  onActiveTabChange(): void {
    this.customerPrepTableModel = [];
  }

  mounted(): void {
    this.projectGuidList = this.projectGuids !== undefined
      ? (JSON.parse(this.projectGuids) as string[])
      : [];
    if (this.projectGuidList) {
      this.fetchAssetHistoryData(this.projectGuidList);
      this.fetchReleaseTableData(this.projectGuidList[0]);
    }
    if (this.projectGuids) {
      this.fetchAssetCountsMetrics(this.projectGuids.replace(/[[\]%"]/g, ''));
    }
    this.getInspectionReleases(this.projectGuidList[0]);
    this.fetchReleaseTableData(this.projectGuidList[0]);

    document.addEventListener('keydown', (e) => {
      if (this.isEditMode()) {
        if (e.key === 'Enter' && this.isEditMode()) this.saveEdit();
        if (e.key === 'Escape') this.toggleEdit();
      }
    });
  }

  /**
   * @description gets if the table is in the selection mode
   * @returns true if the table is in the selection mode
   */
  isInSelectionMode(): boolean {
    return this.isCreateWorkorderMode();
  }

  /**
   * @description gets if the table is in the Edit Selection mode
   * @returns true if the table is in the Edit Selection mode
   */
  isEditMode(): boolean {
    return this.tableMode === TableMode.EDIT;
  }

  /**
   * @description gets if the table is in the Create Work Order Selection mode
   * @returns true if the table is in the Create Work Order Selection mode
   */
  isCreateWorkorderMode(): boolean {
    return this.tableMode === TableMode.CREATE;
  }

  /**
   * @description sets the table mode to normal
   */
  resetTableMode(): void {
    this.tableMode = TableMode.NORMAL;
  }

  /**
   * @description if the table mode is not edit, set it to edit, otherwise, set it to normal
   */
  toggleEdit(): void {
    if (this.tableMode === TableMode.EDIT) {
      if (!this.unsavedChanges) this.tableMode = TableMode.NORMAL;
      else this.showEditWarning = true;
    } else {
      this.beforeEditCache = this.assetHistory.map((a) => ({ ...a }));
      this.tableMode = TableMode.EDIT;
    }
  }

  /**
   * @description if the table mode is not create, set it to create, otherwise, set it to normal
   */
  toggleCreateWorkOrder(): void {
    this.tableMode = this.tableMode !== TableMode.CREATE
      ? TableMode.CREATE : TableMode.NORMAL;
  }

  /**
   * @description Opens the component to create a work order from the selected assets
   */
  openCreateWorkOrderComponent(): void {
    this.showNewAssignDialog = true;
  }

  /**
   * @description Closes the component to create a work order from the selected assets
   */
  closeCreateWorkOrderComponent(): void {
    this.showNewAssignDialog = false;
  }

  /**
   * Called to get the table data again from the api
   */
  refreshTableData(): void {
    this.closeCreateWorkOrderComponent();
    this.resetTableMode();
    this.fetchAssetHistoryData(this.projectGuidList);
    this.$forceUpdate();
  }

  /**
  * @description Saves any changes made to the database
  */
  saveEdit(): void {
    this.patchHistoryData(this.editedAssets);
    this.beforeEditCache = [];
    this.editedAssets = [];
    this.tableMode = TableMode.NORMAL;
    this.showEditWarning = false;
  }

  /**
   * @description Cancels edit mode and reverts any unsaved changes
   */
  cancelEdit(): void {
    this.setAssetHistoryData(this.beforeEditCache);
    this.beforeEditCache = [];
    this.editedAssets = [];
    this.showEditWarning = false;
    this.tableMode = TableMode.NORMAL;
  }

  assetEdited(asset: AssetHistory): void {
    const assetIndex = this.editedAssets.findIndex((a) => a.guid === asset.guid);

    if (assetIndex === -1) {
      // if the asset does not exist, add it to the array to track asset change
      this.editedAssets.push(asset);
    } else if (this.beforeEditCache.find((a) => a.guid === asset.guid).status === asset.status) {
      // if the asset exists i.e. asset change is already being tracked
      // and the change makes it match its original status
      // remove asset from array
      this.editedAssets.splice(assetIndex, 1);
    }
  }

  getAssetParams(item: { guid: string }): any {
    return { idList: JSON.stringify(this.projectGuids), id: item.guid };
  }

  /**
   * @description sets seleted release
   * @param guid of current release
   */
  setSelectedRelease(releaseGuid: string): void {
    this.releaseOpen = true;
    this.selectedRelease = undefined;
    this.selectedSection = 'Releases';
    this.selectedRelease = this.releaseOptions.find(
      (release) => release.releaseGuid === releaseGuid,
    );
    this.$forceUpdate();
  }

  /**
   * @description opens customer prep dialog
   * @param array of new inspections to pass into dialog
   */
  setIsCustomerPrepReleaseDialogOpen(value: any[]): void {
    this.isCustomerPrepReleaseDialogOpen = true;
    this.customerPrepTableModel = value;
  }

  /**
   * @description sets list of releases
   * @param array of releases
   */
  setReleases(releases: Release[]): void {
    this.releaseOptions = releases;
  }

  toggleRelease(): void {
    this.releaseOpen = !this.releaseOpen;
    this.selectedRelease = undefined;
  }

  getMonthFromNumber(month: number): string {
    switch (month) {
      case 1:
        return 'January';
      case 2:
        return 'February';
      case 3:
        return 'March';
      case 4:
        return 'April';
      case 5:
        return 'May';
      case 6:
        return 'June';
      case 7:
        return 'July';
      case 8:
        return 'August';
      case 9:
        return 'September';
      case 10:
        return 'October';
      case 11:
        return 'November';
      case 12:
        return 'December';
      default:
        return '';
    }
  }

  onReleaseTableBack(): void {
    this.selectedRelease = undefined;
    this.releaseOpen = false;
  }

  removeReleaseInspection(item: ReleaseTableData): void {
    const itemIndex = this.releaseTableData.findIndex((data) => data === item);
    this.releaseTableData.splice(itemIndex, 1);
  }

  resetCustomerPrep(): void {
    this.customerPrepTableModel = [];
    (this.$refs.customerPrep as CustomerPrepDialog).reset();
  }
}
