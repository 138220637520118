



























































































































































































































































































/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import {
  Component, Prop, PropSync, Watch, Mixins,
} from 'vue-property-decorator';
import { AssetData, InspectionData, IPDEntry } from '@/store/asset/types';
import { namespace } from 'vuex-class';
import { AssetActions } from '@/store/asset/actions';
import { DefectPanTiltZoom } from '@/store/defectCoding/types';
import util from '../../views/Asset/utils';
import DefectCoding from '../DefectCoding/DefectCoding.vue';
import ImageCrop from '../ImageCrop/ImageCrop.vue';
import HotkeysInfoPopup from '../Hotkeys/HotkeysInfoPopup.vue';
import { VideoActionCommands } from './types';
import UserPermissionsMixin from '../UserPermissions/UserPermissionsMixin.vue';

const assetModule = namespace('asset');

@Component({
  components: {
    DefectCoding,
    ImageCrop,
    HotkeysInfoPopup,
  },
})
export default class Video360 extends Mixins(Vue, UserPermissionsMixin) {
  @assetModule.State('videoOrientationError') videoOrientationError!: string | undefined;

  @assetModule.State('videoOrientationLoaded') videoOrientationLoaded: boolean;

  @assetModule.State('manualIPDZeroTimeLoading') manualIPDZeroTimeLoading: boolean;

  @assetModule.Action(AssetActions.POST_VIDEO_ORIENTATION) postVideoOrientation;

  @assetModule.Action(AssetActions.POST_MANUAL_IPD_ZERO_TIME) postManualIPDZeroTime;

  @assetModule.Action(AssetActions.RESET_MANUAL_IPD_ZERO_TIME) resetManualIPDZeroTime;

  @Prop() videoSource: string | undefined;

  @Prop() videoState: number | undefined;

  @Prop({ default: true }) toggleHUD: boolean | undefined;

  @Prop() readonly asset!: AssetData;

  @Prop() readonly jestInspection: InspectionData;

  @Prop() readonly displayImperial: boolean;

  @PropSync('syncedInspection') realInspection!: InspectionData;

  @PropSync('syncedVideoSpeed') videoSpeed: number;

  @Prop() readonly playbackSpeeds: number

  @Prop() readonly payout!: number;

  @Prop() readonly isMSIView: boolean = false;

  @Prop() readonly ipd: IPDEntry[];

  @Prop() readonly defectPanTiltZoom: DefectPanTiltZoom;

  @Prop() readonly timeToZeroMS: number;

  SCALE_MINIMUM = 1

  SCALE_MAXIMUM = 5

  videoActionCommands = VideoActionCommands;

  snack = false;

  snackBarMessage = '';

  snackColor = 'green';

  headersCreated = false;

  lastInspectionGuid = '';

  scale = 1;

  videoDuration = 0;

  currentTime = 0;

  blockTimeChange = false;

  playing = false;

  reversePlaying = false;

  videoWidth = 0;

  videoHeight = 0;

  screenShotURI = '';

  displayDefectCodingPopup = false;

  hotkeyDisplayPopup = false;

  payoutSnapshot = 0;

  timeRequestMade = null;

  codingInitiated = false;

  screenshotWidth = 4096;

  screenshotHeight = 2048;

  croppedImageURL = '';

  quickObservationCode = '';

  originalRotation = '';

  animationFrame = null;

  panTiltZoom?: DefectPanTiltZoom = null;

  defectCodingAdjustPayoutDisplay = 0

  /**
   * @returns true if the user has the permission INSPECTION_VIEWER_CODING
   */
  get hasPermissionInspectionViewerCoding(): boolean {
    return this.hasPermission(this.permissions.INSPECTION_VIEWER_CODING);
  }

  /**
   * @returns true if the user has the permission INSPECTION_VIEWER_TRIM_MANHOLE_VIDEO
   */
  get hasPermissionInspectionViewerManholeTrim(): boolean {
    return this.hasPermission(this.permissions.INSPECTION_VIEWER_TRIM_MANHOLE_VIDEO);
  }

  get videosphereRotation(): string {
    return this.asset.type === 'Manhole' ? '-90 0 0' : '0 90 0';
  }

  get inspections(): InspectionData[] {
    const inspections = [];
    const foundInspections = [];
    if (this.asset.inspections) {
      this.asset.inspections.forEach((insp) => {
        if (!foundInspections.includes(insp.guid)) {
          inspections.push(insp);
          foundInspections.push(insp.guid);
        }
      });
    }
    return inspections;
  }

  get inspection(): InspectionData {
    if (this.realInspection == null) {
      return this.jestInspection;
    }
    return this.realInspection;
  }

  get zeroTime(): number {
    return this.timeToZeroMS / 1000;
  }

  get hasManualIPDTime(): boolean {
    return this.inspection?.manualIPDZeroTime != null;
  }

  get setManualIPDTimeButtonIcon(): string {
    return this.hasManualIPDTime ? 'mdi-cached' : 'mdi-content-cut';
  }

  get setManualIPDTimeButtonTooltip(): string {
    return this.hasManualIPDTime
      ? 'Reset the Manhole Rim Video Positon'
      : 'Trim to Start Video Here (Set at Manhole Rim)';
  }

  get isManholeAsset(): boolean {
    return this.asset?.type === 'Manhole';
  }

  mounted(): void {
    this.changeVideoTime(this.zeroTime);
    this.lastInspectionGuid = `video360-${this.inspection.guid}`;
    this.originalRotation = this.videosphereRotation;
    if (this.inspection.videoOrientation != null) {
      const camera = document.querySelector('a-camera') as HTMLElement;
      camera.setAttribute('rotation', `${this.inspection.videoOrientation.pitch} ${this.inspection.videoOrientation.yaw} ${this.inspection.videoOrientation.roll}`);
      this.originalRotation = `${this.inspection.videoOrientation.pitch} ${this.inspection.videoOrientation.yaw} ${this.inspection.videoOrientation.roll}`;
    }

    if (document.getElementsByClassName('video360').length !== 0) {
      this.asset.inspections.forEach((insp) => {
        const video = document.getElementById(
          `video360-${insp.guid}`,
        ) as HTMLVideoElement;
        video.addEventListener('timeupdate', () => {
          if (this.blockTimeChange) {
            this.blockTimeChange = false;
          } else {
            this.$emit('timeUpdate', video.currentTime);
            this.$emit(
              'timePercentUpdate',
              ((video.currentTime - this.zeroTime)
                / (video.duration - this.zeroTime))
                * 100,
            );
          }
          this.currentTime = (video.currentTime - this.zeroTime) * 100;
        });

        const setVideoWidthHeightMock = this.setVideoWidthHeight;
        video.addEventListener(
          'loadedmetadata',
          () => {
            setVideoWidthHeightMock(video.videoWidth, video.videoHeight);
          },
          false,
        );

        video.onended = this.videoEnded;
        video.onplay = this.onPlaying;
        video.onplaying = this.onPlaying;
        video.ontimeupdate = this.onTimeUpdate;
      });

      this.onResize();
      (document.getElementById('ascene') as HTMLElement).onwheel = this.onWheel;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (document.querySelector('a-camera') as any).addEventListener(
        'componentchanged',
        this.onRotate,
      );
    }

    const scenediv = document.getElementById('scenediv') as HTMLElement;

    if (scenediv) {
      this.screenshotWidth = scenediv.offsetWidth;
      this.screenshotHeight = scenediv.offsetHeight;
    }

    // document.addEventListener('keydown', this.handleQuickCoding);
    // document.addEventListener('keyup', this.handleQuickCoding);

    // This is a patch to get the video to load
    // setTimeout(() => {
    //   this.changeVideoTime(this.currentVideoTime);
    // }, 500);
  }

  @Watch('displayDefectCodingPopup')
  onSelectedDefectCodeChange(): void {
    if (!this.displayDefectCodingPopup) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.DefectCodingComponent as any).resetForm();
    }
  }

  @Watch('videoSource')
  async onVideoSourceChange(value: string): Promise<void> {
    const videoPlayer = (document.getElementById(this.lastInspectionGuid) as HTMLVideoElement);
    if (videoPlayer == null) return;
    videoPlayer.pause();
    this.headersCreated = false;
    this.scale = 1;
    this.videoDuration = 0;
    this.currentTime = 0;
    this.blockTimeChange = false;
    this.playing = false;
    this.changeVideoTime(this.zeroTime);
    const video = document.getElementById(
      this.getSourceId(),
    ) as HTMLVideoElement;
    video.src = value;
    this.$emit('fullTimeUpdate', video.duration);
    this.onLoaded();
    this.lastInspectionGuid = `video360-${this.inspection.guid}`;
    this.setVideoWidthHeight(video.videoWidth, video.videoHeight);
  }

  @Watch('videoState')
  onVideoStateChange(value: number): void {
    const video = document.getElementById(
      this.getSourceId(),
    ) as HTMLVideoElement;
    if (value === 1) {
      if (video.currentTime === video.duration) {
        this.changeVideoTime(this.zeroTime);
      }
      video.play();
    } else if (value === 2) {
      video.pause();
    }
    this.$emit('updatePauseState', 0);
  }

  @Watch('videoSpeed')
  onVideoSpeedChange(): void{
    this.pauseVideo();
  }

  @Watch('defectPanTiltZoom', { deep: true })
  onPanTiltZoomChange(): void {
    const camera = document.getElementById('cam') as any;
    if (
      this.defectPanTiltZoom?.pan != null
      && this.defectPanTiltZoom?.tilt !== null
    ) {
      // Apply pan and tilt
      (camera.getAttribute('rotation') as any).x = this.defectPanTiltZoom.pan;
      (camera.getAttribute('rotation') as any).y = this.defectPanTiltZoom.tilt;

      // Set camera zoom to the zoom level or 1 if it is null
      let cameraZoom = this.defectPanTiltZoom.zoom ?? 1;
      // Check if the zoom is smaller or greater than our allowed zoom level
      // If so, set it to the minimum zoom level
      if (cameraZoom < this.SCALE_MINIMUM
        || cameraZoom > this.SCALE_MAXIMUM
      ) {
        cameraZoom = this.SCALE_MINIMUM;
      }
      // Apply the zoom level to the camera
      // And also apply it to the value that keeps track of the current zoom level
      this.scale = cameraZoom;
      camera.setAttribute('zoom', cameraZoom);
    }
  }

  resetClockPosition(): void {
    const camera = document.querySelector('a-camera') as HTMLElement;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    camera.setAttribute('rotation', this.originalRotation);
  }

  setVideoOrientation(): void{
    const camera = document.querySelector('a-camera') as HTMLElement;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rotation = (camera.getAttribute('rotation') as any);
    const videoOrientation = {
      pitch: rotation.x,
      yaw: rotation.y,
      roll: rotation.z,
    };
    this.originalRotation = `${videoOrientation.pitch} ${videoOrientation.yaw} ${videoOrientation.roll}`;
    this.onRotate();
    this.postVideoOrientation({
      inspectionGuid: this.inspection.guid,
      orientation: videoOrientation,
    });
    this.snack = true;
    this.snackBarMessage = 'Video Orientation Set';
  }

  async setManualIPDZeroTime(): Promise<void> {
    if (this.inspection != null) {
      if (this.hasManualIPDTime) {
        await this.resetManualIPDZeroTime(this.inspection.guid);
        window.location.reload();
      } else {
        const video = document.getElementById(
          this.getSourceId(),
        ) as HTMLVideoElement;
        const setIpdTime = Math.round(video.currentTime * 1000);
        await this.postManualIPDZeroTime({
          inspectionGuid: this.inspection.guid,
          ipdTime: setIpdTime,
          ipdPayout: util.getClosestPayoutValue(this.ipd, setIpdTime / 1000),
        });
        window.location.reload();
      }
    }
  }

  // Take a screenshot of the video and initiate the cropping function on shift keydown
  handleQuickCoding(e: KeyboardEvent): void {
    this.codingInitiated = e.shiftKey;

    if (this.codingInitiated && !this.displayDefectCodingPopup) {
      // The shift key has been pressed
      // Initialize quick coding by taking a screenshot of the video
      if (e.key === 'Shift') {
        const canvas = (
          document.getElementById('ascene') as any
        ).components.screenshot.getCanvas('perspective');
        const ctx = canvas.getContext('2d');

        ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height);
        this.screenShotURI = canvas.toDataURL('image/png');
      } else {
        this.setQuickObservation(e.key);
      }
    }
  }

  setQuickObservation(key: string): void {
    switch (key) {
      case '!':
        this.quickObservationCode = 'MGO';
        break;
      case '@':
        this.quickObservationCode = 'SRI';
        break;
      case '#':
        this.quickObservationCode = 'SAV';
        break;
      case '$':
        this.quickObservationCode = 'VC';
        break;
      case '%':
        this.quickObservationCode = 'RFJ';
        break;
      case '^':
        this.quickObservationCode = 'DSF';
        break;
      case '&':
        this.quickObservationCode = 'DSGV';
        break;
      case '*':
        this.quickObservationCode = 'DB';
        break;
      case '(':
        this.quickObservationCode = 'IS';
        break;
      case ')':
        this.quickObservationCode = 'IW';
        break;
      default:
        break;
    }

    if (this.quickObservationCode !== '') {
      this.displayDefectCodingPopup = true;
      this.hotkeyDisplayPopup = false;
    }
  }

  async openCodingPopup(quickOb: boolean): Promise<void> {
    this.pauseVideo();

    // Get pan tilt zoom for creating a new defect
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const camera = document.getElementById('cam') as any;
    this.panTiltZoom = {
      pan: (camera.getAttribute('rotation') as any).x,
      tilt: (camera.getAttribute('rotation') as any).y,
      zoom: parseFloat(camera.getAttribute('zoom')),
    };

    const snapshotDistanceReadout = document.getElementById('distanceReadout');
    if (snapshotDistanceReadout != null) {
      this.payoutSnapshot = parseFloat(
        snapshotDistanceReadout.innerHTML
          .replace(/\s/g, '')
          .replace(/[^\d.-]/g, ''),
      );
      this.defectCodingAdjustPayoutDisplay = util.getStartingPayout(this.inspection,
        this.timeToZeroMS);
      if (this.defectCodingAdjustPayoutDisplay < 0) {
        this.defectCodingAdjustPayoutDisplay = 0;
      }
      this.defectCodingAdjustPayoutDisplay = util.getDisplayDistanceFtM(this.displayImperial,
        this.defectCodingAdjustPayoutDisplay / 100);
      this.payoutSnapshot += this.defectCodingAdjustPayoutDisplay;
      if (!this.displayImperial) {
        this.payoutSnapshot = Math.round(this.payoutSnapshot * (this.displayImperial ? 10 : 100))
          / (this.displayImperial ? 10 : 100);
      }
    }
    this.displayDefectCodingPopup = true;
    if (!quickOb) this.getScreenShot();
    else this.screenShotURI = this.croppedImageURL;
  }

  openHotkeyPopup(): void {
    this.hotkeyDisplayPopup = true;
  }

  setPlaybackSpeed(speed: number): void {
    this.inspections.forEach((insp) => {
      const video = (document.getElementById(this.getVideoId(insp.guid)) as HTMLVideoElement);
      video.playbackRate = speed;
    });
    this.videoSpeed = speed;
  }

  async getScreenShot(): Promise<void> {
    this.screenShotURI = null;

    if (window.Worker) {
      const worker = new Worker('./webworker.js', { type: 'module' });
      this.timeRequestMade = Date.now();

      const canvasList = document.getElementsByClassName('a-canvas');
      const canvas = canvasList.length > 0
        ? (
              document.querySelector('a-scene') as any
        ).components.screenshot.getCanvas('perspective')
        : (document.getElementById(this.getSourceId()) as HTMLVideoElement);

      const offscreen = new OffscreenCanvas(
        this.screenshotWidth,
        this.screenshotHeight,
      );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const context = offscreen.getContext('2d') as any;
      context.drawImage(
        canvas,
        0,
        0,
        this.screenshotWidth,
        this.screenshotHeight,
      );
      const imgData = context.getImageData(
        0,
        0,
        this.screenshotWidth,
        this.screenshotHeight,
      );

      worker.postMessage({
        type: 'takeScreenshot',
        timeRequestMade: this.timeRequestMade,
        imageData: imgData,
        width: this.screenshotWidth,
        height: this.screenshotHeight,
      });

      worker.onmessage = (e) => {
        const data = JSON.parse(e.data);
        if (
          data.type != null
          && data.type === 'takeScreenshot'
          && this.timeRequestMade === data.timeRequestMade
        ) {
          this.screenShotURI = data.screenShotURI;
        }
      };
    }
  }

  setVideoWidthHeight(width: number, height: number): void {
    this.videoWidth = width;
    this.videoHeight = height;
  }

  getInspectionVideoSource(): string {
    return this.videoSource;
  }

  onSwitchView(timeUpdate: number): void {
    this.changeVideoTime(timeUpdate);
    this.setPlaybackSpeed(this.videoSpeed);
  }

  // eslint-disable-next-line class-methods-use-this
  pause(): void {
    const video = document.getElementById(
      this.getSourceId(),
    ) as HTMLVideoElement;
    video.pause();
  }

  onLoaded(): void {
    const video = document.getElementById(
      this.getSourceId(),
    ) as HTMLVideoElement;
    this.videoDuration = (video.duration - this.zeroTime) * 100;
    this.currentTime = (video.currentTime - this.zeroTime) * 100;
    video.playbackRate = this.videoSpeed;
    this.$emit('fullTimeUpdate', video.duration);
  }

  emitCommand(value: number): void {
    let cvt = 0;
    const video = document.getElementById(
      this.getSourceId(),
    ) as HTMLVideoElement;
    switch (value) {
      case VideoActionCommands.BACKWARD_10:
        cvt = video.currentTime - this.zeroTime;
        cvt -= 10;
        if (cvt < 0) {
          this.$emit('timeUpdate', this.zeroTime);
          this.changeVideoTime(this.zeroTime);
        } else {
          this.$emit('timeUpdate', cvt);
          this.changeVideoTime(cvt);
        }
        break;
      case VideoActionCommands.BACKWARD_STEP:
        cvt = video.currentTime;
        cvt -= 0.25;
        if (cvt < 0) {
          this.$emit('timeUpdate', this.zeroTime);
          this.changeVideoTime(this.zeroTime);
        } else {
          this.$emit('timeUpdate', cvt);
          this.changeVideoTime(cvt);
        }
        break;
      case VideoActionCommands.BACKWARD_SKIP:
        this.$emit('timeUpdate', this.zeroTime);
        this.changeVideoTime(this.zeroTime);
        break;
      case VideoActionCommands.PAUSE_PLAY:
        window.cancelAnimationFrame(this.animationFrame);
        video.onseeked = null;

        // Make sure we can never play and reverse at the same time
        this.reversePlaying = false;

        if (this.playing) {
          video.pause();
        } else {
          if (video.ended) {
            this.changeVideoTime(this.zeroTime);
          }
          video.play();
        }
        break;
      case VideoActionCommands.REVERSE_PAUSE_PLAY:
        window.cancelAnimationFrame(this.animationFrame);
        video.onseeked = null;
        this.reversePlaying = !this.reversePlaying;

        // Make sure we can never play and reverse at the same time
        this.playing = false;
        video.pause();

        // eslint-disable-next-line no-case-declarations
        const reverseSpeed = this.videoSpeed;

        if (this.reversePlaying) {
          const duration = video.seekable.end(0);
          let start = null;

          const step = (timestamp) => {
            if (!start) start = timestamp;
            const progress = timestamp - start;
            const time = (progress * reverseSpeed) / 1000;

            video.onseeked = () => {
              video.onseeked = null;
              window.requestAnimationFrame(step);
            };

            video.currentTime -= time;
            start = timestamp;

            // loop
            if (time > duration) {
              start = null;
            }

            // exit animation frame for begining of video
            if (video.currentTime === 0) {
              video.onseeked = null;
            }
          };
          this.animationFrame = window.requestAnimationFrame(step);
        }
        break;
      case VideoActionCommands.FORWARD_SKIP:
        this.$emit('timeUpdate', video.duration);
        this.changeVideoTime(video.duration);
        break;
      case VideoActionCommands.FORWARD_STEP:
        cvt = video.currentTime;
        cvt += 0.25;
        if (cvt > video.duration) {
          this.$emit('timeUpdate', video.duration);
          this.changeVideoTime(video.duration);
        } else {
          this.$emit('timeUpdate', cvt);
          this.changeVideoTime(cvt);
        }
        break;
      case VideoActionCommands.FORWARD_10:
        cvt = video.currentTime;
        cvt += 10;
        if (cvt > video.duration) {
          this.$emit('timeUpdate', video.duration);
          this.changeVideoTime(video.duration);
        } else {
          this.$emit('timeUpdate', cvt);
          this.changeVideoTime(cvt);
        }
        break;
      default:
        break;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onChangeScrollBar(): void {
    const video = document.getElementById(
      this.getSourceId(),
    ) as HTMLVideoElement;
    const slider = document.getElementById('slider') as HTMLInputElement;
    video.currentTime = parseInt(slider.getAttribute('value') as string, 10) / 100
      + this.zeroTime;
  }

  changeVideoTime(value: number): void {
    const video = document.getElementById(
      this.getSourceId(),
    ) as HTMLVideoElement;
    if (video) {
      video.pause();
      video.currentTime = value;
      this.$emit(
        'timePercentUpdate',
        ((value - this.zeroTime) / (video.duration - this.zeroTime)) * 100,
      );
      this.$emit('timeUpdate', value);
      this.currentTime = (value - this.zeroTime) * 100;
    }
  }

  changeVideoTimeNoPercent(value: number): void {
    const video = document.getElementById(
      this.getSourceId(),
    ) as HTMLVideoElement;
    if (video) {
      video.pause();
      this.blockTimeChange = true;
      video.currentTime = value;
      this.$emit('timeUpdate', value);
      this.currentTime = (value - this.zeroTime) * 100;
    }
  }

  videoEnded(): void {
    const video = document.getElementById(
      this.getSourceId(),
    ) as HTMLVideoElement;
    this.$emit('timeUpdate', video.duration);
    this.onEnded();
  }

  onWheel(e: WheelEvent): void {
    if (e.cancelable) {
      e.preventDefault();
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cam = document.getElementById('cam') as any;
    const delta = Math.sign(e.deltaY) * -0.1;
    if (delta < 0 && this.scale > this.SCALE_MINIMUM) {
      cam.setAttribute('zoom', this.scale + delta);
      this.scale += delta;
    } else if (delta > 0 && this.scale < this.SCALE_MAXIMUM) {
      cam.setAttribute('zoom', this.scale + delta);
      this.scale += delta;
    }
  }

  onRotate(): void {
    const camera = document.querySelector('a-camera') as HTMLElement;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const split = this.originalRotation.split(' ');
    let rotatey = (camera.getAttribute('rotation') as any).y - parseFloat(split[1]);
    this.$emit('onRotateChange', rotatey);
    if (rotatey <= -360) {
      rotatey += 360;
    }
    if (rotatey >= 360) {
      rotatey -= 360;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (camera.getAttribute('rotation') as any).y = rotatey + parseFloat(split[1]);
  }

  onResize(): void {
    const div = document.getElementById('scenediv') as HTMLElement;
    const ascene = document.getElementById('ascene') as HTMLElement;
    ascene.style.width = `${window
      .getComputedStyle(div, null)
      .getPropertyValue('width')}`;
    ascene.style.height = `${window
      .getComputedStyle(div, null)
      .getPropertyValue('height')}`;
    const height = parseInt(
      window.getComputedStyle(div, null).getPropertyValue('height'),
      10,
    );
    const width = parseInt(
      window.getComputedStyle(div, null).getPropertyValue('width'),
      10,
    );
    const camera = (ascene as any)?.camera;
    if (camera) {
      (ascene as any).camera.aspect = width / height;
      (ascene as any).camera.updateProjectionMatrix();
    }
    this.$emit('changeHeight', height);
  }

  onPlaying(): void {
    this.playing = true;
  }

  onPause(): void {
    this.playing = false;
  }

  onEnded(): void {
    this.playing = false;
  }

  pauseVideo(): void{
    if (this.playing) {
      this.emitCommand(VideoActionCommands.PAUSE_PLAY);
    }
    if (this.reversePlaying) {
      this.emitCommand(VideoActionCommands.REVERSE_PAUSE_PLAY);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onTimeUpdate(evt: any): void {
    // Pause the video if we get to the begining and are reversing
    if (evt.srcElement.currentTime <= 0 && this.reversePlaying) {
      this.emitCommand(VideoActionCommands.REVERSE_PAUSE_PLAY);
    }
  }

  timeChange(distance: number): void {
    this.changeVideoTime(
      util.getClosestTime(this.inspection.payoutIPD, distance) / 1000,
    );
  }

  getVideoId(inspGuid: string): string {
    return `video360-${inspGuid}`;
  }

  getSourceId(): string {
    return `video360-${this.inspection.guid}`;
  }
}
