






































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-curly-newline */
import { InspectionRelease } from '@/store/releases/types';
import Vue from 'vue';
import { Component, Emit, Prop, PropSync } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { namespace } from 'vuex-class';
import utils from '@/components/Report/util';
import IntegrityTable, { AdditionalFilterFunction, FillFunctionContainer, FilterFunction, processDateWithTableObject } from '@/components/IntegrityTable/IntegrityTable.vue';
import { uuid } from 'vue-uuid';

const releasesModule = namespace('releases');

@Component({
  components: {
    IntegrityTable,
  },
})
export default class CustomerPrep extends Vue {
  @PropSync('activeTab') readonly syncedActiveTab: number;

  @Prop() readonly projectGuids: string;

  @releasesModule.State('inspectionReleases')
  inspectionReleases: InspectionRelease[];

  @releasesModule.State('loadingInspectionReleases')
  loadingInspectionReleases: boolean;

  tableHeight: string | number = '100%';

  customerPrepTableModel = [];

  search = '';

  hasMedia = 'Has Media';

  noMediaAvailable = 'No Media Available';

  tabOptions = [
    { name: 'Manhole', value: 'Manhole' },
    { name: 'Line Segment', value: 'Line Segment' },
  ];

  custMatchFilters = [
    {
      header: 'assetId',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    { header: 'hasCustomerDeliverables', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    {
      header: 'status',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'score',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'release',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'platform',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'dateInspected',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'dateReleased',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'dateShipped',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'upManhole',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'dnManhole',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'direction',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'lengthSurveyed',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ]

  get isCustomerPrepReleaseButtonDisabled(): boolean {
    return this.customerPrepTableModel == null
      || this.customerPrepTableModel.length === 0;
  }

  get custPrepHeaders(): DataTableHeader[] {
    const headers = [
      {
        text: 'Asset ID',
        value: 'assetId',
        type: 'string',
        sortable: true,
        filterable: true,
        class: 'sticky',
        cellClass: 'sticky',
      },
      {
        text: '',
        value: 'hasCustomerDeliverables',
        type: 'boolean',
        sortable: true,
        filterable: true,
        class: 'sticky',
        cellClass: 'sticky',
      },
      {
        text: 'Reporting Status',
        value: 'status',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Score',
        value: 'score',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Release',
        value: 'release',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Platform',
        value: 'platform',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Date Inspected',
        value: 'dateInspected',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Date Released',
        value: 'dateReleased',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Date Shipped',
        value: 'dateShipped',
        type: 'string',
        sortable: true,
        filterable: true,
      },
    ];

    const lsHeaders = [
      {
        text: 'US MH',
        value: 'upManhole',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'DS MH',
        value: 'dnManhole',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Direction',
        value: 'direction',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Length Surveyed',
        value: 'lengthSurveyed',
        type: 'string',
        sortable: true,
        filterable: true,
        stopFilterValueGeneration: true,
      },
    ];

    if (this.syncedActiveTab === 1) headers.splice(4, 0, ...lsHeaders);

    return headers;
  }

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'hasCustomerDeliverables',
          functionVariables: [this.hasMedia, this.noMediaAvailable],
          fillFunction: function fillHasCustomerDeliverables(
            item: InspectionRelease,
            hasMedia: string,
            noMediaAvailable: string,
          ): string {
            if (item.hasCustomerDeliverables) {
              return hasMedia;
            }
            return noMediaAvailable;
          },
        },
        {
          headerValue: 'score',
          functionVariables: [],
          fillFunction: function fillScore(
            item: InspectionRelease,
          ): string {
            return item?.score == null || item?.score === -1 ? 'Unscored' : item.score.toString();
          },
        },
        {
          headerValue: 'dateInspected',
          functionVariables: ['dateInspected'],
          fillFunction: processDateWithTableObject,
        },
        {
          headerValue: 'dateReleased',
          functionVariables: ['dateReleased'],
          fillFunction: processDateWithTableObject,
        },
        {
          headerValue: 'dateShipped',
          functionVariables: ['dateShipped'],
          fillFunction: processDateWithTableObject,
        },
      ],
    };
    return returnValue;
  }

  get additionalFilterFunctions(): AdditionalFilterFunction {
    const filterFunctions: FilterFunction[] = [
      {
        functionVariables: [this.syncedActiveTab],
        filterFunction: function isAssetType(item, activeTab) {
          if (activeTab === 0) {
            return item.assetType === 'Manhole'
            && (item.status === 'QA Required'
              || item.status === 'Ready For Release'
              || item.status === 'Released'
            )
            && item.score != null;
          }
          return item.assetType === 'Line Segment'
            && (item.status === 'QA Required'
              || item.status === 'Ready For Release'
              || item.status === 'Released'
            )
            && item.score != null;
        },
      },
    ];
    return {
      updateKey: uuid.v4(),
      filterFunctions,
    };
  }

  get customerPrepTableData(): InspectionRelease[] {
    // check bad data
    if (
      this.inspectionReleases == null
      || this.inspectionReleases.length === 0
    ) {
      return undefined;
    }
    return this.inspectionReleases;
  }

  @Emit('setIsCustomerPrepReleaseDialogOpen')
  setIsCustomerPrepReleaseDialogOpen(): any[] {
    return this.customerPrepTableModel;
  }

  getScoreColorTable(score: number | string): string {
    const realScore = Number.parseInt(score.toString(), 10);
    if (Number.isNaN(realScore)) {
      utils.getScoreColor(-1);
    }
    return utils.getScoreColor(realScore);
  }

  reset(): void {
    this.customerPrepTableModel = [];
  }
}
