var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-container"},[_c('div',{attrs:{"id":"table-header"}},[_c('div',{attrs:{"id":"card-header"}},[_c('v-btn',{staticClass:"back-btn",attrs:{"icon":""},on:{"click":_vm.onBackButtonClick}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chevron-left")])],1),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.releaseName)+" ")])],1),_c('v-spacer'),_c('div',{attrs:{"id":"asset-metrics"}},[_c('div',[_c('span',{staticClass:"metric-label"},[_vm._v("Release Total: ")]),_c('span',{staticClass:"metric-value"},[_vm._v(_vm._s(_vm.releaseTableMetrics.releaseTotals)+_vm._s(_vm.unitLabel))])]),_c('span',[_vm._v(" | ")]),_c('div',[_c('span',{staticClass:"metric-label"},[_vm._v("Inspections: ")]),_c('span',{staticClass:"metric-value"},[_vm._v(_vm._s(_vm.releaseTableMetrics.inspections))])]),_c('span',[_vm._v(" | ")]),_c('div',[_c('span',{staticClass:"metric-label"},[_vm._v("Inspection Dates: ")]),_c('span',{staticClass:"metric-value"},[_vm._v(" "+_vm._s(_vm.releaseTableMetrics.earliestInspectionDate)+" - "+_vm._s(_vm.releaseTableMetrics.latestInspectionDate)+" ")])])]),_c('v-text-field',{staticClass:"search-bar",attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"outline-btn",attrs:{"outlined":"","color":"#0c6599","id":"exportBtn"},on:{"click":_vm.openExportDialog}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-export-variant")]),_vm._v(" Export ")],1)],1),_c('IntegrityTable',{key:"release-overview-table",attrs:{"data":_vm.filteredTableData,"headers":_vm.tableHeaders,"height":_vm.tableHeight,"matchFilters":_vm.matchFilters,"dataFillFunctions":_vm.fillTableDataFunctions,"itemsPerPage":100,"search":_vm.search,"footer-props":{
      'disable-items-per-page': false,
      'items-per-page-options': [100, 250, 500, -1],
    },"loading":_vm.releaseTableDataLoading},on:{"update:data":function($event){_vm.filteredTableData=$event},"update:height":function($event){_vm.tableHeight=$event},"update:matchFilters":function($event){_vm.matchFilters=$event},"update:match-filters":function($event){_vm.matchFilters=$event}},scopedSlots:_vm._u([{key:"hasCustomerDeliverables",fn:function(ref){
    var item = ref.item;
return [(item.hasCustomerDeliverables)?_c('a',{staticClass:"info-btn",staticStyle:{"text-decoration":"none"},attrs:{"tag":"button","href":_vm.getNavHref(item.assetGuid)}},[_c('v-icon',[_vm._v("mdi-information-outline")])],1):_c('div')]}},{key:"score",fn:function(ref){
    var item = ref.item;
return [_vm._t("score",[(item.score != null && item.score.toString() !== '-1')?_c('div',[_vm._v(_vm._s(item.score))]):(item.score == null)?_c('div',[_vm._v("-1")]):_c('div',[_vm._v("Unscored")])],{"item":item})]}},{key:"actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{attrs:{"id":"actions-buttons"}},[_c('v-btn',{staticClass:"undo-btn",attrs:{"icon":"","disabled":!_vm.canDelete},on:{"click":function($event){return _vm.resetStatus(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-u-left-top")])],1)],1)]}}],null,true)}),_c('ExportReleaseDialog',{attrs:{"isOpen":_vm.exportDialog,"releaseGuid":_vm.releaseGuid,"projectGuid":_vm.projectGuid},on:{"update:isOpen":function($event){_vm.exportDialog=$event},"update:is-open":function($event){_vm.exportDialog=$event}}}),(_vm.releaseToUndo)?_c('div',[_c('v-dialog',{attrs:{"width":"35vw"},model:{value:(_vm.showUndoDialog),callback:function ($$v) {_vm.showUndoDialog=$$v},expression:"showUndoDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Are you sure you want to remove "+_vm._s(_vm.releaseToUndo.assetID)+" from release "+_vm._s(_vm.releaseName)+"? ")]),_c('v-card-text',[_vm._v(" This will move "+_vm._s(_vm.releaseToUndo.assetID)+" back to a ready to release status. ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"#0c6599","outlined":""},on:{"click":function($event){_vm.showUndoDialog = false}}},[_vm._v(" No, Cancel ")]),_c('v-spacer'),_c('v-btn',{staticClass:"integrity-blue-button",attrs:{"outlined":"","loading":_vm.deleteReleaseInspectionLoading},on:{"click":_vm.undo}},[_vm._v(" Yes, Remove ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }