
























































































































































import { BasicSelect } from 'vue-search-select';
import { AdminActions } from '@/store/admin/actions';
import { User } from '@/store/admin/types';
import { ProjectActions } from '@/store/project/actions';
import { Component, PropSync, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AuthUserRoles, canUserManageRole } from '@/auth/roles';
import CustomerDialogSelectOrganization from '@/components/Admin/Customers/CustomerDialogSelectOrganization.vue';
import { OrganizationData } from '@/store/organizations/types';
import { uuid } from 'vue-uuid';
import { SelectManagedUsersData, SelectProjectData } from '@/store/users/types';
import { UserPermission } from '@/store/userpermissions/types';
import { CustomerData } from '@/store/customers/types';
import AdminPanelMixin from '../AdminPanelMixin.vue';
import PermissionsMatrix from './PermissionsMatrix.vue';
import CustomerEditDialog from '../Customers/CustomerEditDialog.vue';
import SelectProjects from './SelectProjects.vue';
import SelectManagedUsers from './SelectManagedUsers.vue';
import CustomerDialogSelectCustomer from '../Customers/CustomerDialogSelectCustomer.vue';

const adminModule = namespace('admin');
const projectModule = namespace('project');

@Component({
  components: {
    BasicSelect,
    CustomerDialogSelectOrganization,
    PermissionsMatrix,
    CustomerEditDialog,
    SelectProjects,
    SelectManagedUsers,
    CustomerDialogSelectCustomer,
  },
})
export default class CreateUser extends AdminPanelMixin {
  @adminModule.Action(AdminActions.POST_NEW_USER) postUserData;

  @adminModule.State('loadError') postUserError;

  @adminModule.State('loading') loadingPostUsers;

  @adminModule.State('response') userpostResponse;

  @projectModule.Action(ProjectActions.FETCH_ALL_PROJECTS_DATA)
  fetchAllProjectsData;

  @projectModule.State('loadError') loadError;

  @projectModule.State('loading') loading;

  @PropSync('userData') synchedUserData: Array<{
    role: any,
    auth0Id: any,
    detailedUserData: any,
    detailedUser: any,
    isExpanded: boolean
    visible: boolean
    resetDialog: boolean,
    guid?: string,
    fullName?: string
  }>;

  projects: Array<{ value: number; text: string; guid: string }> = [];

  snack = false;

  snackBarMessage = '';

  snackColor = 'black';

  startDateModel = false;

  endDateModel = false;

  newUserStep = 1;

  // Variables for form for user
  firstName = '' as string;

  lastName = '' as string;

  fieldName = '' as string;

  email = '' as string;

  userOrganization = {} as OrganizationData;

  userCustomer = {} as CustomerData;

  newUserRole = '';

  lastProjectGuid = null as string;

  nasscoId = '' as string;

  // Variables for form for user roles

  userRoleGuid = null as string;

  roleGuid = '';

  managerGuid = null as string;

  startDate = null as string;

  endDate = null as string;

  selectedProject = { value: 0, text: '', guid: '' } as {
    value: number;
    text: string;
    guid: string;
  };

  newUserSelectedProjects = [] as SelectProjectData[];

  newUserSelectedManagedUsers = [] as SelectManagedUsersData[];

  valid = false as boolean;

  addUserDialog = false as boolean;

  get customerOptions(): { name: string, guid: string }[] {
    if (!this.customerDataList) {
      return [];
    }
    return this.customerDataList
      .filter((c) => c.organizationGuid === this.userOrganization.guid)
      .map((cust) => ({ name: cust.name, guid: cust.guid }));
  }

  get isFirstNewUserStep(): boolean {
    return this.newUserStep === 1;
  }

  get isLastNewUserStep(): boolean {
    return this.newUserStep === 3;
  }

  get roleItems(): string[] {
    const retVal = AuthUserRoles
      .map((r) => r.name)
      .filter((role) => canUserManageRole(this.$auth.user.role, role));
    return retVal;
  }

  /**
   * @returns true if the user has the permission ADMIN_CUSTOMER_SECTION_CREATEACUSTOMER
   */
  get hasPermissionAdminCreateACustomer(): boolean {
    return this.hasPermission(UserPermission.ADMIN_CUSTOMER_SECTION_CREATEACUSTOMER);
  }

  @Watch('userpostResponse')
  userPostResponseChange(): void {
    if (this.userpostResponse == null) {
      return;
    }
    this.snackBarMessage = this.userpostResponse.data;
    this.snackColor = 'green';
    this.snack = true;
  }

  @Watch('postUserError')
  userPostErrorChange(): void {
    if (this.postUserError == null) {
      return;
    }
    this.snackBarMessage = 'An error occured posting that User';
    this.snackColor = '#e61e25';
    this.snack = true;
  }

  @Watch('userOrganization')
  onUserOrganizationChange(): void {
    this.userCustomer = {} as CustomerData;
  }

  async mounted(): Promise<void> {
    this.fetchAllProjectsData()
      .catch(() => {
        this.$router.push({
          name: 'Error',
          params: {
            catchAll: 'Error',
            message: 'There was an error retrieving your projects. Please try again later. If this issue persists, please contact support.',
          },
        });
      });
  }

  async submit(): Promise<void> {
    let user = this.createUser();
    const customerUser = {
      customerGuid: this.userCustomer.guid,
      userGuid: user.Guid,
    };
    const organizationUser = {
      organizationGuid: this.userOrganization.guid,
      userGuid: user.Guid,
    };

    try {
      user = await this.$auth.createUser(user);

      // create new user
      await this.postUserData(user);

      // create new customer-user relationship
      await this.postCustomerUser(customerUser);

      // create new organization-user relationship
      await this.postOrganizationUser(organizationUser);

      // create new user management relationships
      this.newUserSelectedManagedUsers.forEach(async (managedUser) => {
        const userManagement = {
          managerUserGuid: user.Guid,
          subjectUserGuid: managedUser.userGuid,
          organizationGuid: this.userOrganization.guid,
          customerGuid: this.userCustomer.guid,
        };

        await this.postUserManagement(userManagement);
      });
    } catch (ex) {
      this.snackBarMessage = (ex as Error).message;
      this.snackColor = '#e61e25';
      this.snack = true;
      return;
    }
    await this.$auth.sendPasswordReset(this.email);
    await this.resetForm();

    this.addUserDialog = false;
    this.$emit('onAddUser');
  }

  closeDialog(): void {
    this.addUserDialog = false;
    this.resetForm();
  }

  async resetForm(): Promise<void> {
    this.roleGuid = '';
    this.email = '';
    this.firstName = '';
    this.lastName = '';
    this.fieldName = '';
    this.email = '';
    this.userOrganization = {} as OrganizationData;
    this.userCustomer = {} as CustomerData;
    this.newUserStep = 1;
    this.lastProjectGuid = '';
    this.newUserRole = '';
    this.nasscoId = '';
    this.userRoleGuid = '';
    this.managerGuid = '';
    this.startDate = '';
    this.endDate = '';
    this.newUserSelectedProjects = [];
    this.newUserSelectedManagedUsers = [];
    await (this.$refs.userCreateForm as HTMLFormElement).resetValidation();
  }

  createUser(): User {
    const currentUser = this.allUsers.find((u) => u.useridentity === `auth0|${this.$auth.user.id}`);
    const user = {} as User;

    user.Guid = uuid.v4();
    user.Nickname = this.email;
    user.Firstname = this.firstName;
    user.Lastname = this.lastName;
    user.Fieldname = this.fieldName;
    user.Email = this.email;
    user.LastProjectGuid = this.lastProjectGuid;
    user.Nasscoid = this.nasscoId;
    user.Role = AuthUserRoles.find((r) => r.name === this.newUserRole).guid;
    user.ManagerUserGuid = currentUser == null ? '' : currentUser.guid;
    user.Projects = this.newUserSelectedProjects.map((p) => p.projectGuid);

    return user;
  }

  onAddUser(): void {
    if (this.synchedUserData.length > 0) {
      this.synchedUserData = [];
    }
    this.fetchInactiveDetailedUserData();
    this.fetchAllDetailedUserData().then(this.$emit('updateAllUsers'));
  }

  /**
   * @returns true if the user has the permission ADMIN_ORGANIZATION_SECTION_CREATEANORGANIZATION
   */
  get hasPermissionAdminCreateAnOrganization(): boolean {
    return this.hasPermission(UserPermission.ADMIN_ORGANIZATION_SECTION_CREATEANORGANIZATION);
  }

  get validPage(): boolean {
    switch (this.newUserStep) {
      case 1:
        return this.valid;
      case 2:
      case 3:
        return true;
      default:
        return false;
    }
  }
}
