




















































































































































import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import { EdititableUser, UserRoleObject } from '@/store/admin/types';
import {
  DetailedUserData, ManagedUsersTableData, UserManagement, UserRole,
} from '@/store/users/types';
import { UserPermission } from '@/store/userpermissions/types';
import { uuid } from 'vue-uuid';
import {
  getRoleGuid,
  getRoleId,
  getRoleIdByGuid,
  getRoleString,
  AuthUserRoles,
} from '../../../auth/roles';
import PermissionsMatrix from '../User/PermissionsMatrix.vue';
import AddProjectButton from './AddProjectButton.vue';
import AddCustomerButton from './AddCustomerButton.vue';
import AddManagedUserButton from './AddManagedUserButton.vue';
import EditUserCustomers from './EditUserCustomers.vue';
import EditUserProject from './EditUserProject.vue';
import EditUsersManagedUsers from './EditUsersManagedUsers.vue';
import AdminPanelMixin, { EditUserData } from '../AdminPanelMixin.vue';

@Component({
  components: {
    PermissionsMatrix,
    EditUserProject,
    EditUserCustomers,
    EditUsersManagedUsers,
    AddProjectButton,
    AddCustomerButton,
    AddManagedUserButton,
  },
})
export default class UserEdit extends AdminPanelMixin {
  @Prop() readonly id!: string;

  @PropSync('selectedUser') synchedSelectedUser: EditUserData;

  activeTab = 0;

  initCustomerGuids: string[] = null;

  initUserRoles: UserRole[] = null;

  isActive = true;

  addProjectDialog = false as boolean;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, arrow-parens
  requiredField = [(v) => !!v || v === 0 || 'This is a required field.'];

  isExpanded = [];

  fullName = '' as string;

  email = '' as string;

  showSnackbar = false as boolean;

  snackbarColor = '' as string;

  snackbarMessage = '' as string;

  userRole = '';

  currentRole = '';

  managedUsers = [] as ManagedUsersTableData[];

  managedUsersForRemoval = [] as ManagedUsersTableData[];

  managedUsersForAddition = [] as DetailedUserData[];

  managedUsersLoading = false;

  /**
   * @returns true if the user has the permission ADMIN_EDIT_USER_PAGE_VIEWCUSTOMERSTAB
   */
  hasPermissionAdminViewCustomersTab(): boolean {
    return this.hasPermission(UserPermission.ADMIN_EDIT_USER_PAGE_VIEWCUSTOMERSTAB);
  }

  /**
   * @returns true if the user has the permission ADMIN_EDIT_USER_PAGE_VIEWPROJECTSTAB
   */
  hasPermissionAdminViewProjectsTab(): boolean {
    return this.hasPermission(UserPermission.ADMIN_EDIT_USER_PAGE_VIEWPROJECTSTAB);
  }

  /**
   * @returns true if the user has the permission ADMIN_EDIT_USER_PAGE_VIEWMANAGEDUSERSTAB
   */
  hasPermissionAdminViewManagedUsersTab(): boolean {
    return this.hasPermission(UserPermission.ADMIN_EDIT_USER_PAGE_VIEWMANAGEDUSERSTAB);
  }

  get tabOptions(): any[] {
    const tabOptions = [];
    if (this.hasPermissionAdminViewCustomersTab) {
      tabOptions.push({
        name: 'Customers',
        value: 'customers',
      });
    }
    if (this.hasPermissionAdminViewProjectsTab) {
      tabOptions.push({
        name: 'Projects',
        value: 'projects',
      });
    }
    if (this.hasPermissionAdminViewManagedUsersTab) {
      tabOptions.push({
        name: 'Managed Users',
        value: 'managedUsers',
      });
    }
    return tabOptions;
  }

  /**
   * @description Get the value from the current tab.
   * @returns {string} 'value' from the current tab
   */
  get activeTabValue(): string {
    if (this.tabOptions.length === 0) return '';
    return this.tabOptions[this.activeTab].value;
  }

  get roleItems(): string[] {
    return AuthUserRoles.map((r) => r.name);
  }

  goToAddUser(): void {
    window.location.href = '/admin';
  }

  async saveUser(): Promise<void> {
    let userManagementTableNeedsUpdated = false;
    try {
      if (this.changes > 0) {
        userManagementTableNeedsUpdated = true;
      }
      const userEdit = this.createUser();
      await this.patchUser(userEdit);

      if (userEdit.email !== this.synchedSelectedUser.detailedUserData.email) {
        await this.$auth.setEmail(
          this.synchedSelectedUser.auth0Id,
          userEdit.email,
        );
      }

      // add new roles
      const newRole = getRoleId(this.currentRole);
      await this.$auth.deleteRoles(this.synchedSelectedUser.auth0Id, [this.userRole]);

      const addResults = await this.$auth.addRoles(this.synchedSelectedUser.auth0Id, [newRole]);

      if (addResults === 'false') {
        throw new Error('Unable to Add Roles');
      }

      // update state to match users new role
      this.userRole = newRole;

      // delete managed users if any
      if (this.managedUsersForRemoval.length > 0) {
        userManagementTableNeedsUpdated = true;
        const guidsToRemoveOriginal = this.managedUsersForRemoval.map((u) => ({
          guid: u.guid,
          userManagementGuid: u.userManagementGuid,
        }));
        this.managedUsersForAddition = this.managedUsersForAddition.filter((u) => {
          const foundIndex = guidsToRemoveOriginal
            .findIndex((remove) => remove.guid === u.guid);
          if (foundIndex !== -1) {
            guidsToRemoveOriginal.splice(foundIndex, 1);
            return false;
          }
          return true;
        });
        await this.deleteUserManagement(guidsToRemoveOriginal
          .map((remove) => remove.userManagementGuid));
        this.managedUsersForRemoval.splice(0, this.managedUsersForRemoval.length);
      }

      // add managed users if any
      if (this.managedUsersForAddition.length > 0) {
        userManagementTableNeedsUpdated = true;
        for (let i = 0; i < this.managedUsersForAddition.length; i += 1) {
          const user = this.managedUsersForAddition[i];
          const newUserManagement: UserManagement = {
            guid: uuid.v4(),
            customerGuid: this.synchedSelectedUser.detailedUserData.customerGuids[0],
            subjectUserGuid: user.guid,
            managerUserGuid: this.synchedSelectedUser.detailedUserData.guid,
            organizationGuid: this.synchedSelectedUser.detailedUserData.organizationGuids[0],
          };
          // eslint-disable-next-line no-await-in-loop
          await this.postUserManagement(newUserManagement);
        }
        this.managedUsersForAddition.splice(0, this.managedUsersForAddition.length);
      }

      if (userManagementTableNeedsUpdated) {
        await this.updateAllUserManagements();
      }
    } catch (ex) {
      this.snackbarColor = '#e61e25';
      this.snackbarMessage = (ex as Error).message;
      this.showSnackbar = true;
      this.$forceUpdate();
      return;
    }
    this.snackbarColor = 'green';
    this.snackbarMessage = 'Changes Saved';
    this.showSnackbar = true;
    this.$forceUpdate();
  }

  /**
   * @description Adds beforeunload listener to window
   */
  created(): void {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  }

  /**
   * @description Removes beforeunload listener to window
   */
  beforeDestroy(): void {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  }

  /**
   * @description Show popup if user has unsaved changes
   */
  beforeWindowUnload(e): void {
    if (this.changes > 0) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
    }
  }

  setInitCustomerData(): void {
    this.initCustomerGuids = [
      ...this.synchedSelectedUser.detailedUserData.customerGuids,
    ];

    this.initUserRoles = [
      ...this.synchedSelectedUser.detailedUserData.role,
    ];
    this.initUserRoles.forEach((ur) => {
      // eslint-disable-next-line no-param-reassign
      ur.startDate = ur.startDate ? new Date(String(ur.startDate).substring(0, 10)) : null;
      // eslint-disable-next-line no-param-reassign
      ur.endDate = ur.endDate ? new Date(String(ur.endDate).substring(0, 10)) : null;
    });
  }

  async mounted(): Promise<void> {
    this.setInitCustomerData();
    await this.fetchOrganizationData();
    await this.fetchCustomerData();
    this.updateAllUserManagements();

    this.setUserRoles();
    if (
      this.synchedSelectedUser.detailedUserData
      && this.synchedSelectedUser.detailedUserData.firstname
      && this.synchedSelectedUser.detailedUserData.lastname
    ) {
      this.fullName = `${this.synchedSelectedUser.detailedUserData.firstname} ${this.synchedSelectedUser.detailedUserData.lastname}`;
    }
    this.email = this.synchedSelectedUser.detailedUserData.email;
    const dbUser = this.allUsers.find(
      (u) => u.useridentity
        === this.synchedSelectedUser.detailedUserData['useridentity'],
    );
    this.isActive = dbUser?.isActive ?? false;
  }

  @Watch('synchedSelectedUser')
  onsynchedSelectedUserChange(): void {
    this.setUserRoles();
  }

  createUser(): EdititableUser {
    const fullNameArray = this.fullName.split(' ');
    const userRoleObjects: UserRoleObject[] = this.synchedSelectedUser
      .detailedUserData.role.map((role) => ({
        ProjectGuid: role.projectGuid,
        RoleGuid: getRoleGuid(this.currentRole),
        StartDate: role.startDate,
        EndDate: role.endDate,
      }));
    const user = {
      Guid: this.id,
      Firstname: fullNameArray[0],
      Lastname: fullNameArray[1],
      userRoleObjects,
      isActive: this.isActive,
      email: this.email,
      organizationGuids:
        this.synchedSelectedUser.detailedUserData.organizationGuids,
      customerGuids: this.synchedSelectedUser.detailedUserData.customerGuids,
    };
    return user;
  }

  getRoleString(role: string): string {
    return getRoleString(role);
  }

  async setUserRoles(): Promise<void> {
    const userRoles = await this.$auth.getRoles(
      `${this.synchedSelectedUser.auth0Id}`,
    );

    if (!userRoles) {
      return;
    }

    // If user has more than 1 row, then delete extra roles to bring user down to 1 role
    if (userRoles.length > 1) {
      console.error('User has multiple roles');
      await this.$auth.deleteRoles(
        this.synchedSelectedUser.auth0Id,
        userRoles.slice(1),
      );
    }
    // eslint-disable-next-line prefer-destructuring
    this.userRole = userRoles[0];

    this.currentRole = getRoleString(this.userRole);
  }

  async updateAllUserManagements(): Promise<void> {
    await this.fetchAllDetailedUserData();
    const foundUser = this.allUsers.find((u) => u.guid === this.id);
    if (!foundUser) {
      this.$router.push({
        name: 'Error',
        params: {
          catchAll: 'Error',
          message:
          `User does not exist with id ${this.id}.`,
        },
      });
    }
    this.synchedSelectedUser = {
      detailedUserData: foundUser,
      auth0Id: foundUser.useridentity,
    };
    if (this.hasPermissionAdminViewManagedUsersTab) {
      this.managedUsers = [];
      this.managedUsersLoading = true;
      this.fetchAllUserManagements().then(() => {
        const { userManagements } = this.synchedSelectedUser.detailedUserData;

        if (userManagements.length > 0) {
          userManagements.forEach((userManagement) => {
            const existsInData = this.managedUsers
              .findIndex((u) => u.guid === userManagement.subjectUserGuid) !== -1;
            if (!existsInData) {
              const managedUser = this.allUsers
                .find((u) => u.guid === userManagement.subjectUserGuid);

              if (managedUser) {
                this.managedUsers.push({
                  guid: managedUser.guid,
                  userManagementGuid: userManagement.guid,
                  user: `${managedUser.firstname} ${managedUser.lastname}`,
                  email: managedUser.email ?? '',
                  role: [...new Set(managedUser.role.map(
                    (user) => getRoleIdByGuid(user.roleGuid),
                  ))],
                  organization: this.getOrganizationString(managedUser),
                  customers: this.getCustomersString(managedUser),
                });
              }
            }
          });
        }
        this.managedUsersLoading = false;
        this.setInitCustomerData();
      });
    }
  }

  getOrganizationString(user: DetailedUserData): string {
    if (!user.organizationGuids || user.organizationGuids.length === 0) return '';

    const organization = this.organizationDataList.find(
      (o) => o.guid === user.organizationGuids[0],
    );

    return organization ? organization.name : '';
  }

  getCustomersString(user: DetailedUserData): string {
    if (!user.customerGuids || user.customerGuids.length === 0) return '';

    const customersString = user.customerGuids
      .map((guid) => this.customerDataList
        .find((c) => c.guid === guid)?.name ?? '');

    return customersString.filter((s) => s !== '').toString().replaceAll(',', ', ');
  }

  symmetricDifference(arr1: string[], arr2: string[]): number {
    return arr1
      .filter((x) => !arr2.includes(x))
      .concat(arr2.filter((x) => !arr1.includes(x))).length;
  }

  get changes(): number {
    if (!this.synchedSelectedUser) {
      return 0;
    }
    const customerDiffs = this.initCustomerGuids
      ? this.symmetricDifference(
        this.initCustomerGuids,
        this.synchedSelectedUser.detailedUserData.customerGuids,
      )
      : 0;

    let dateDiffs = 0;
    let projectDiffs = 0;

    if (this.initUserRoles) {
      this.synchedSelectedUser.detailedUserData.role.forEach((r) => {
        const foundUserRole = this.initUserRoles.find(
          (init) => init.projectGuid === r.projectGuid,
        );
        if (!foundUserRole) {
          return;
        }
        if (
          String(foundUserRole.endDate) !== String(r.endDate)
          || String(foundUserRole.startDate) !== String(r.startDate)
        ) {
          dateDiffs += 1;
        }
      });

      projectDiffs = this.symmetricDifference(
        this.initUserRoles.map((p) => p.guid),
        this.synchedSelectedUser.detailedUserData.role.map((p) => p.guid),
      );
      projectDiffs += this.managedUsersForAddition.length;
      projectDiffs += this.managedUsersForRemoval.length;
    }

    return customerDiffs + projectDiffs + dateDiffs;
  }
}
